import { IonContent, IonHeader, IonGrid, IonRow, IonCol, IonText, IonIcon, IonButton, IonCard, IonCardTitle, IonPage, IonTitle, IonToolbar, IonFooter } from '@ionic/react';
import './Help.css';
import { List } from '../components/BtnlogList'
import { LogButton } from '../components/LogButton';
import { TtsIonText } from '../components/TtsIonText';

const Help: React.FC = () => {
  return (
    <IonPage>
      <IonHeader class="header">
        <IonToolbar>
          <IonRow>
            <IonCol size="3" class="ion-text-start">
              <LogButton linkTo="/home" btnid="Generally-1" class="BackButton" content="Zurück" />
            </IonCol>
            <IonCol size="6" class="ion-text-center">
              <IonTitle id="PageTitle">Anleitung</IonTitle>
            </IonCol>
            <IonCol size="3" class="ion-text-end">
            </IonCol>
          </IonRow>
        </IonToolbar>
      </IonHeader>
      <IonContent class="MenuContainer" fullscreen>
        <IonGrid>
          <IonRow class="ion-text-center">
            <IonCol size="12">
              <IonCard>
              <IonText class="helptext">Herzlich Willkommen in der Bedienungsanleitung der "DeinHaus 4.0" App.<br /><br />
                Je nach Nutzergruppe, der Sie zugeteilt wurden, stehen Ihnen unterschiedliche Funktionen zur Verfügung.<br /><br />
                Wundern Sie sich also nicht, wenn nicht alle Möglichkeiten für Sie verfügbar sind.<br />
                Auf der Startseite der App finden Sie vier große Schaltflächen: <br /><br />
                - Meine Gesundheitsdaten<br />
                - Meine Wohndaten<br />
                - Meine Schulungen<br />
                - Allgemeine Infos<br /><br />
                In der unteren Leiste der App finden Sie zudem die Schaltflächen Anleitung, Sprachausgabe und Kontakt.<br /><br />
                Wenn Sie im Rahmen der Studie Geräte für Ihre Gesundheit erhalten haben (hierzu zählen das Blutdruckmessgerät, die Körperwaage und die Schlafmatte), können Sie über die Schaltfläche "Meine Gesundheitsdaten" Informationen zu den von den Geräten gelieferten Werten erhalten.<br /><br />
                Hierzu können Sie sich entweder die Gesamtübersicht ansehen oder durch Drücken auf die einzelnen Schaltflächen nähere Informationen erhalten.<br />
                  Wenn Sie Haus-Sensorik erhalten haben, wie beispielsweise Tür- und Fensterkontakte, einen CO-Melder etc., können Sie über "Meine Wohndaten" diese Informationen abrufen.<br />
                
                  Unter der Schaltfläche "Meine Schulungen" finden Sie das Begrüßungsvideo zur Studie von Herrn Prof. Dr. Horst Kunhardt.<br />
                  Sollten Sie sich in einer Gruppe befinden, die weitere Schulungs- und Übungsvideos erhält, finden Sie hier in regelmäßigen Abständen, einmal wöchentlich ein neues Schulungsvideo.
                <br />
                Ältere Schulungsvideos finden Sie im Videoarchiv. Zu diesem Archiv gelangen Sie im Bereich meine Schulungen durch Drücken der rechten oberen Schaltfläche namens "Schulungs-Archiv".<br /><br />
                Im Bereich "Allgemeine Informationen" finden Sie weiterführende Informationen zu Normwerten sowie eine technische Hilfestellung zu den Geräten. <br /><br />
                Sollten Sie Hilfe benötigen, können Sie uns neben klassischem Anruf und Anrufbeantworter über die Schaltfläche "Kontakt" benachrichtigen. Hierbei können Sie auswählen, ob Sie -  je nach Frage oder Problem - mit einem technischen Mitarbeiter oder einen Büromitarbeiter sprechen wollen.<br /><br />
                Viel Spaß bei der Nutzung der App!</IonText><br /><br />
              </IonCard>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
      <IonFooter class="footer">

      </IonFooter>
    </IonPage>
  );
};

export default Help;
