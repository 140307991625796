import { IonContent, IonHeader, IonGrid, IonRow, IonCol, IonButton, IonText, IonPage, IonTitle, IonToolbar, IonIcon, IonFooter, IonLabel } from '@ionic/react';
import './Survey.css';
import { checkmarkCircle } from 'ionicons/icons';
import { LogButton, TtsSpeak } from '../components/LogButton';
import { TtsIonText } from '../components/TtsIonText';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { logEntryAdded } from '../redux/btnlogSlice';
import { setIsSurveyAnsweredTrue } from '../redux/archiveSlice';
import { useHistory } from 'react-router';

const Survey: React.FC = () => {

  const dispatch = useAppDispatch();

  const isQuestionAnswered = useAppSelector(state => state.archive.isSurveyAnswered);
  const videoNumber = useAppSelector(state => state.archive.videoNumber);
  const history = useHistory();


  const surveyBox = (questionNumber: number) => {
    
    const getQuestion = (num: number) =>{
      switch (num) {
        case 0:
          return "Wie fanden Sie dieses Video?";
          break;
        case 1:
          return "War der Inhalt des Videos brauchbar für Sie?";
          break;
        case 2:
          return "Wollen Sie zukünftig mehr zu diesem Thema erfahren?";
          break;
        default:
          return "";
          break;
      }
    }

    const getIdNumbers = (num: number) =>{
      switch (num) {
        case 0:
          return [2,3,4];
          break;
        case 1:
          return [5,6,7];
          break;
        case 2:
          return [8,9,10];
          break;
        default:
          return [];
          break;
      }
    }


    const onButtonClick = (num: number, btnid: String) => {
      dispatch(setIsSurveyAnsweredTrue(num));
      
      //Log the button click
      const logEntry = {
        buttonId: btnid,
        timestamp: new Date().toLocaleString()
      };
      dispatch(logEntryAdded(logEntry));
      
      //Redirect the user to /home if all questions are answered
      let counter = 0;
      for (var i=0; i<3; i++){
        if (isQuestionAnswered[i]){ counter++; }
      }
      if(counter>1){
        setTimeout(()=>{history.push("/home");}, 400);
      }
      
    }



    if (isQuestionAnswered[questionNumber]){
      return (
        <div className="SurveyBox">
          <IonIcon size="large" class="checkmark" icon={checkmarkCircle} />
          <TtsIonText content="Vielen Dank für Ihre Bewertung."/>
        </div>
      );
    } else {
      return (
        <div className="SurveyBox">
          <TtsIonText content={getQuestion(questionNumber)}/><br/>
          <IonRow>
            <IonCol size="4">
              <IonButton class="VoteButton ButtonGood" id={"Survey-Video"+videoNumber+"-Button-"+getIdNumbers(questionNumber)[0]} onClick={() => {onButtonClick(questionNumber, "Survey-Video"+videoNumber+"-Button-"+getIdNumbers(questionNumber)[0])}}>
                <IonIcon class="SurveyIcon" src="assets/icon/SmileyGood.svg" />
              </IonButton>
              {/*Answer to the first question is "Gut", to the other questions it is "Ja" */}
              <br/><TtsIonText content={questionNumber? "Ja" : "Gut" }/>
            </IonCol>
            <IonCol size="4">
              <IonButton class="VoteButton ButtonMedium" id={"Survey-Video"+videoNumber+"-Button-"+getIdNumbers(questionNumber)[1]} onClick={() => {onButtonClick(questionNumber, "Survey-Video"+videoNumber+"-Button-"+getIdNumbers(questionNumber)[1])}}>
                <IonIcon class="SurveyIcon" src="assets/icon/SmileyMedium.svg" />
              </IonButton>
              {/*Answer to the first question is "Mittel", to the other questions it is "Vielleicht" */}
              <br/><TtsIonText content={questionNumber? "Vielleicht" : "Mittel" }/>
            </IonCol>
            <IonCol size="4">
              <IonButton class="VoteButton ButtonBad" id={"Survey-Video"+videoNumber+"-Button-"+getIdNumbers(questionNumber)[2]} onClick={() => {onButtonClick(questionNumber, "Survey-Video"+videoNumber+"-Button-"+getIdNumbers(questionNumber)[2])}}>
                <IonIcon class="SurveyIcon" src="assets/icon/SmileySad.svg" />
              </IonButton>
              {/*Answer to the first question is "Schlecht", to the other questions it is "Nein" */}
              <br/><TtsIonText content={questionNumber? "Nein" : "Schlecht" }/>
            </IonCol>
          </IonRow>
        </div>
      )
    }
  }
  
  
   
  return (
    <IonPage>
      <IonHeader class="header">
      <IonToolbar>
          <IonRow>
            <IonCol size="3" class="ion-text-start">
              <LogButton linkTo="/home" btnid="Survey-1" class="BackButton" content="Zurück"/>
            </IonCol>
            <IonCol size="6" class="ion-text-center">
              <IonTitle id="PageTitle">Umfrage</IonTitle>
            </IonCol>
            <IonCol size="3" class="ion-text-end">
            </IonCol>
          </IonRow>
        </IonToolbar>
      </IonHeader>
      <IonContent class="MenuContainer" fullscreen>
        <IonGrid>
          <IonRow class="ion-text-center">
            <IonCol size="12">
              <TtsIonText id="Alert" content="Einen Moment bitte!" /><br/>
              <TtsIonText id="SubAlert" content="Wir würden uns freuen, wenn Sie das letzte Video bewerten. Drücken Sie zum Bewerten auf die Smileys." />
            </IonCol>
            <IonCol size="12">
              {surveyBox(0)}
              {surveyBox(1)}
              {surveyBox(2)}
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Survey;
