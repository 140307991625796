import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { IonText, IonButton, IonIcon, IonCol, IonCard, IonRow, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCardContent, IonImg } from '@ionic/react';
import { logEntryAdded } from '../redux/btnlogSlice';
import { Link } from 'react-router-dom';
import { TtsSpeak } from './LogButton';
import { playCircle } from 'ionicons/icons';


export function LogTrainingCard(props: any) {

  const dispatch = useAppDispatch();

  const isTtsOn = useAppSelector(state => state.tts.isOn);
  const lastButtonPressed = useAppSelector(state => state.btnlog.arr[state.btnlog.arr.length-1].buttonId);
  const isSecondTimePressed = (props.btnid == lastButtonPressed);

  const onSavePostClicked = () => {
    dispatch(
      logEntryAdded({
        buttonId: props.btnid,
        timestamp: new Date().toLocaleString()
      })
    );

    //Read the label out loud if TTS is activated, but don't do it on the second click
    if (isTtsOn && !isSecondTimePressed) {
      TtsSpeak(props.cardtitle+". "+props.contenttext);
    }
  }
  
  var image=props.image;
  if (!image){
    image="assets/icon/dh40_logo.png";
  }

  const getTrainingCard = () => {
      return(
        <IonCard class={props.cardclass} id={props.btnid} onClick={onSavePostClicked}>
        <IonRow>
          <IonCol size="12">
            <div className="videopreview">
              <div className="overlay"></div>
              <IonIcon icon={playCircle} className="playicon"/>
              <IonImg class="logo" src={image}></IonImg>
            </div>
            <IonCardHeader>
              <IonCardSubtitle class="SubTitle">{props.subtitle}</IonCardSubtitle>
              <IonCardTitle class="CardTitle">{props.cardtitle}</IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
              <IonText class="ContentText">{props.contenttext}</IonText>
            </IonCardContent>
          </IonCol>
        </IonRow>
      </IonCard>
      );
  }

  if ((typeof props.linkTo !== 'undefined') && (isSecondTimePressed || !isTtsOn)){
    return (<Link to={props.linkTo}>{getTrainingCard()}</Link>);
  }else {
    return(<>{getTrainingCard()}</>);
  }
}