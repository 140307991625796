import { IonText } from '@ionic/react';
import { useAppSelector } from '../redux/hooks';
import { TtsSpeak } from './LogButton';


export function TtsIonText(props: any) {
  const isTtsOn = useAppSelector(state => state.tts.isOn);

  const readText = (event: any) => {
    if (isTtsOn){
      TtsSpeak(event.target.innerText);
    }
  }

  return (
    <IonText id={props.id} class={props.class} onClick={event => readText(event)}>{props.content}</IonText>
  );
}