import React, { useState } from "react";
import { IonContent, IonHeader, IonRow, IonCol, IonText, IonIcon, IonButton, IonPage, IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent, IonTitle, IonToolbar, IonSlides, IonLabel } from '@ionic/react';
import './Reference.css';
import { LogButton, TtsSpeak } from '../components/LogButton';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { logEntryAdded } from '../redux/btnlogSlice';
import { TtsIonText } from "../components/TtsIonText";
import { alertCircleOutline } from "ionicons/icons";


const title = [
  "Körperfett-Anteil",
  "Muskelmassen-Anteil",
  "Knochenmasse in % nach Körpergewicht",
  "Gesamtkörperwasser in %",
  "Normwerte der Pulswellengeschwindigkeit",
  "Blutdruck-Klassifikation",
  "Puls",
  "Herzfrequenz"
];


const ReferenceHealthData: React.FC = () => {

  const [openCard, setOpenCard] = useState(-1); //number of the datacard that is shown in single view, -1 means that the overall view is shown

  const dispatch = useAppDispatch();
  const isTtsOn = useAppSelector(state => state.tts.isOn);
  const [lastButtonPressed, setLastButtonPressed] = useState(useAppSelector(state => state.btnlog.arr[state.btnlog.arr.length-1].buttonId));


  const changeDataDisplay = (text: string, id: string, num: number) => {
    if (isTtsOn && lastButtonPressed!== id){
      TtsSpeak(text);
    } else {
      setOpenCard(num);
    }

    dispatch(logEntryAdded({
      buttonId: id,
      timestamp: new Date().toLocaleString()
    }));
    setLastButtonPressed(id);
  }

  const readText = (event: any) => {
    if (isTtsOn){
      TtsSpeak(event.target.innerText);
    }
  }

  //dataCards in overall view
  var dataCard = (num: number) => {
    return (
      <IonCard class="DataCards"  onClick={() => changeDataDisplay(title[num], `HealthReferenceCard-${num}`, num)}>
      <IonText class="CardTitle">{title[num]}</IonText>
      </IonCard>
    )
  }

  //datacard in single view mode
  var bigDataCard = (num: number) => {
    switch (num) {
        case 0:
          //Körperfett-Anteil
          return (
            <>
            <IonRow class="ReferenceRow">
              <IonCol size="4">
                <b>Alter</b>
              </IonCol>
              <IonCol size="4" class="middleCol">
                <b>Frauen</b>
              </IonCol>
              <IonCol size="4">
                <b>Männer</b>
              </IonCol>
            </IonRow>
            <IonRow class="ReferenceRow LastRow">
              <IonCol size="4">
                über 60 Jahre
              </IonCol>
              <IonCol size="4" class="middleCol">
                25 % - 36 %
              </IonCol>
              <IonCol size="4">
                13 % - 25 %
              </IonCol>
            </IonRow>
            </>
          );
          break;
        case 1:
          //Muskelmassen-Anteil
          return (
            <>
            <IonRow class="ReferenceRow">
              <IonCol size="4">
                <b>Alter</b>
              </IonCol>
              <IonCol size="4" class="middleCol">
                <b>Frauen</b>
              </IonCol>
              <IonCol size="4">
                <b>Männer</b>
              </IonCol>
            </IonRow>
            <IonRow class="ReferenceRow LastRow">
              <IonCol size="4">
                über 60 Jahre
              </IonCol>
              <IonCol size="4" class="middleCol">
                60 % - 72,5 %
              </IonCol>
              <IonCol size="4">
                70 % - 84 %
              </IonCol>
            </IonRow>
            </>
            );
          break;
        case 2:
          //Knochenmasse in % nach Körpergewicht
            return (
              <div className="GridContainer ReferenceRow">
                {/*Row 1 */}
                <div className="GridItem1"></div>
                <div className="GridItem2"><b>Frauen</b></div>
                <div className="GridItem3"><b>Männer</b></div>
                {/*Row 2 */}
                <div className="GridItem4">Gewicht</div>
                <div className="GridItem5">{"<"} 45 kg</div>
                <div className="GridItem6">45-60 kg</div>
                <div className="GridItem7">{">"} 60 kg</div>
                <div className="GridItem8">{"<"} 60 kg</div>
                <div className="GridItem9">60-75 kg</div>
                <div className="GridItem10">{">"} 75 kg</div>
                {/*Row 3 */}
                <div className="GridItem11 LastRow">Knochenmasse</div>
                <div className="GridItem12 LastRow">1,8 kg</div>
                <div className="GridItem13 LastRow">2,2 kg</div>
                <div className="GridItem14 LastRow">2,5 kg</div>
                <div className="GridItem15 LastRow">2,5 kg</div>
                <div className="GridItem16 LastRow">2,9 kg</div>
                <div className="GridItem17 LastRow">3,2 kg</div>
              </div>
            );
          break;
        case 3:
          //Gesamtkörperwasser in %
            return (
              <>
              <IonRow class="ReferenceRow">
                Durchschnittlich gesunder Bereich für Frauen
              </IonRow>
              <div className="ColorGridContainer ReferenceRow">
                {/*Row 1 */}
                <div className="GridItem1 Terrible"></div>
                <div className="GridItem2 Great"></div>
                <div className="GridItem3 Great"></div>
                <div className="GridItem4 Great"></div>
                <div className="GridItem5 Terrible"></div>
                <div className="GridItem6 Terrible LastColorItem"></div>
                {/*Row 2 */}
                <div className="GridItem7 LastRow">40%</div>
                <div className="GridItem8 LastRow">45%</div>
                <div className="GridItem9 LastRow">50%</div>
                <div className="GridItem10 LastRow">55%</div>
                <div className="GridItem11 LastRow">60%</div>
                <div className="GridItem12 LastRow">
                  <p className="LeftNumber">65%</p>
                  <p className="RightNumber">70%</p>
                </div>
              </div>
              <IonRow class="ReferenceRow">
                Durchschnittlich gesunder Bereich für Männer
              </IonRow>
              <div className="ColorGridContainer ReferenceRow">
                {/*Row 1 */}
                <div className="GridItem1 Terrible"></div>
                <div className="GridItem2 Terrible"></div>
                <div className="GridItem3 Great"></div>
                <div className="GridItem4 Great"></div>
                <div className="GridItem5 Terrible"></div>
                <div className="GridItem6 Terrible LastColorItem"></div>
                {/*Row 2 */}
                <div className="GridItem7 LastRow">40%</div>
                <div className="GridItem8 LastRow">45%</div>
                <div className="GridItem9 LastRow">50%</div>
                <div className="GridItem10 LastRow">55%</div>
                <div className="GridItem11 LastRow">60%</div>
                <div className="GridItem12 LastRow">
                  <p className="LeftNumber">65%</p>
                  <p className="RightNumber">70%</p>
                </div>
              </div>
              </>
            );
          break;
        case 4:
          //Normwerte der Pulswellengeschwindigkeit
          return (
            <>
            <IonRow class="ReferenceRow">
              <div id="PulsDiv">Je niedriger die Pulswellengeschwindigkeit, desto gesünder ist das Herz.</div>
            </IonRow>
            <IonRow class="ReferenceRow">
              <IonCol size="4">
                <b>Alter</b>
              </IonCol>
              <IonCol size="8" >
                <b>Medianwert</b>
              </IonCol>
            </IonRow>
            <IonRow class="ReferenceRow">
              <IonCol size="4">
                60 - 69 Jahre
              </IonCol>
              <IonCol size="8">
                7,9 - 13,1 m/s
              </IonCol>
            </IonRow>
            <IonRow class="ReferenceRow LastRow">
              <IonCol size="4">
                &gt;70 Jahre
              </IonCol>
              <IonCol size="8">
                8,0 - 14,6 m/s
              </IonCol>
            </IonRow>
            </>
          );
          break;
        case 5:
          //Blutdruck-Klassifikation
          return (
            <>
            <IonRow class="ReferenceRow">
              <IonCol size="4">
                <b>Kategorie</b>
              </IonCol>
              <IonCol size="4" class="middleCol">
                <b>Systolisch (mmHg)</b>
              </IonCol>
              <IonCol size="4">
                <b>Diastolisch (mmHg)</b>
              </IonCol>
            </IonRow>
            <IonRow class="ReferenceRow Great">
              <IonCol size="4">
                Optimal
              </IonCol>
              <IonCol size="4" class="middleCol">
                {"<"} 120
              </IonCol>
              <IonCol size="4">
                {"<"} 80
              </IonCol>
            </IonRow>
            <IonRow class="ReferenceRow Great">
              <IonCol size="4">
                Normal
              </IonCol>
              <IonCol size="4" class="middleCol">
                120-129
              </IonCol>
              <IonCol size="4">
                80-84
              </IonCol>
            </IonRow>
            <IonRow class="ReferenceRow Average">
              <IonCol size="4">
                Hochnormal
              </IonCol>
              <IonCol size="4" class="middleCol">
                130-139
              </IonCol>
              <IonCol size="4">
                85-89
              </IonCol>
            </IonRow>
            <IonRow class="ReferenceRow Terrible">
              <IonCol size="4">
                Hypertonie Grad 1
              </IonCol>
              <IonCol size="4" class="middleCol">
                140-159
              </IonCol>
              <IonCol size="4">
                90-99
              </IonCol>
            </IonRow>
            <IonRow class="ReferenceRow Terrible">
              <IonCol size="4">
                Hypertonie Grad 2
              </IonCol>
              <IonCol size="4" class="middleCol">
                160-179
              </IonCol>
              <IonCol size="4">
                100-109
              </IonCol>
            </IonRow>
            <IonRow class="ReferenceRow Terrible">
              <IonCol size="4">
                Hypertonie Grad 3
              </IonCol>
              <IonCol size="4" class="middleCol">
                {"≥"} 180
              </IonCol>
              <IonCol size="4">
                {"≥"} 110
              </IonCol>
            </IonRow>
            <IonRow class="ReferenceRow Terrible LastRow">
              <IonCol size="4">
                Isolierte systolische Hypertonie
              </IonCol>
              <IonCol size="4" class="middleCol">
                {"≥"} 140
              </IonCol>
              <IonCol size="4">
                {"<"} 90
              </IonCol>
            </IonRow>
            </>
          );
          break;
        case 6:
          //Puls
          return (
            <IonRow class="ReferenceRow">
              Der Richtwert für den Ruhepuls liegt bei Erwachsenen zwischen 60 bis 80 Schlägen pro Minute. Bei Senioren kann der Puls in Ruhe leicht erhöht bis 85 Schlägen pro Minute sein.
            </IonRow>
          );
          break;
        case 7:
        //Herzfrequenz
          return (
            <IonRow class="ReferenceRow">
              Die Herzfrequenz soll bei über 60-Jährigen den Wert von 160 möglichst nicht überschreiten.
            </IonRow>
          );
          break;
        default:
          return (
            <TtsIonText content="Es ist ein Fehler aufgetreten. Wir entschuldigen uns für die Unannehmlichkeiten." />
          )
          break;
    }
  }


  if (openCard < 0){
    return (
      <IonPage>
          <IonHeader className="header">
          <IonToolbar>
              <IonRow>
                <IonCol size="3" class="ion-text-start">
                <LogButton linkTo="/generally" btnid="ReferenceHealthData-1" class="BackButton" content="Zurück"/>
                </IonCol>
                <IonCol size="6" class="ion-text-center">
                <IonTitle id="PageTitle">Richtwerte - Körperdaten</IonTitle>
                </IonCol>
                <IonCol size="3" class="ion-text-end">
                </IonCol>
              </IonRow>
              </IonToolbar>
          </IonHeader>
          <IonContent class="DataCardsBox" fullscreen>
          <IonRow class="DataRow">
            <IonCol size="12">
              <IonCard id="NoticeCard">
                <IonIcon icon={alertCircleOutline} size="large"/> <IonLabel>Hinweis</IonLabel>
                <p>Das Projekt DeinHaus 4.0 weist daraufhin, dass die hier veröffentlichten Informationen nicht die individuelle Beurteilung und Empfehlung eines Arztes oder Ärztin ersetzen kann, sondern dienen ausschließlich Informationszwecken. Sie stellen eine Orientierungshilfe für ein informiertes Arzt-Patienten-Gespräch dar. Individuelle Beratungsleistungen können durch das Projekt DeinHaus 4.0 nicht geleistet werden. Bei gesundheitlichen Beschwerden oder Verschlechterung wenden Sie sich bitte an Ihren Hausarzt oder Hausärztin.</p>
              </IonCard>
            </IonCol>
            <IonCol size="12">
              {dataCard(0)}
              {dataCard(1)}
              {dataCard(2)}
              {dataCard(3)}
              {dataCard(4)}
              {dataCard(5)}
              {dataCard(6)}
              {dataCard(7)}
            </IonCol>
          </IonRow>
          </IonContent>
      </IonPage>
    );
  } else {
  return (
    <IonPage>
        <IonHeader className="header">
          <IonToolbar>
            <IonRow>
              <IonCol size="3" class="ion-text-start">
              <IonButton id="HealthReference-1" class="BackButton" onClick={() => changeDataDisplay("Zurück", "ReferenceHealthData-1", -1)}>Zurück</IonButton>
              </IonCol>
              <IonCol size="6" class="ion-text-center">
              <IonTitle id="PageTitle">Richtwerte - Körperdaten</IonTitle>
              </IonCol>
              <IonCol size="3" class="ion-text-end">
              </IonCol>
            </IonRow>
          </IonToolbar>
        </IonHeader>
        <IonContent class="DataCardsBox" fullscreen>
          <br/><IonCard class="bigDataCard"  onClick={(event) => readText(event)}>
            <IonCardTitle class="bigCardTitle">{title[openCard]}</IonCardTitle>
            {bigDataCard(openCard)}
          </IonCard>
        </IonContent>
    </IonPage>
  );
}
};



export default ReferenceHealthData;
