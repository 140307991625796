// @ts-nocheck
import React, { useState, useEffect } from "react";
import { InfluxDB, ClientOptions } from "@influxdata/influxdb-client-browser";
import { ResponsiveHeatMapCanvas } from '@nivo/heatmap'
import {IonButton, IonCol, IonGrid, IonIcon, IonRow, IonSpinner} from "@ionic/react";
import {checkboxOutline, squareOutline} from "ionicons/icons";

import { INFLUX_USERNAME, INFLUX_PASSWORD, INFLUX_BUCKET, URL_INFLUX } from "../config";

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
const SecurityDataHeatmap = ({ dataId, measureds, measuredDetails }) => {

  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  const RANGE_24H = '1';
  const RANGE_7D = '7';
  const RANGE_30D = '30';
  const [range, setRange] = useState(RANGE_24H);

  /**
   * The interval of the aggregate window.
   */
  const buckets = {
      [RANGE_24H]: "1h",
      [RANGE_7D]: "1h",
      [RANGE_30D]: "1h",
    };

  function getPreviousDay(days = 1, date = new Date()) {
    const previous = new Date(date.getTime());
    previous.setHours(1,0,0,0);  // Start chart at 00:00
    previous.setDate(date.getDate() - days);

    return previous;
  }

  function queryBuilder(range) {
    if(dataId === 'dataMoving') {
      const q = `import "experimental"
import "date"

baseTime = now()
timeStart = ${ getPreviousDay(range).toISOString() }
timeStop = date.truncate(t: experimental.addDuration(d: ${buckets[range]}, to: now()), unit: ${buckets[range]})

from(bucket: "sensors/autogen")
  |> range(start: timeStart, stop: timeStop) 
  |> filter(fn: (r) => r.measured_detail == "Motion_sensor_status")
  |> group(columns: ["measured", "measured_detail"])
  |> aggregateWindow(every: ${buckets[range]}, fn: count)
`;
      console.log(q);
      return q;
    }
    else {
      throw Error(`No query for ${dataId} defined!`)
    }
  }

  /**
   * Query the influx data for the given range.
   * @param range The time range.
   */
  function queryInflux(range) {
    if(range !== RANGE_24H && range !== RANGE_7D && range !== RANGE_30D) {
      throw new Error('Time range "' + range + '" not implemented.');
    }

    let res = [];
    const influxQuery = async () => {
      //create InfluxDB client
      const org = ''  // There is no org in InfluxDB v1
      const options: ClientOptions = {
        url: URL_INFLUX,
        token: `${INFLUX_USERNAME}:${INFLUX_PASSWORD}`,
      }
      const queryApi = await new InfluxDB(options).getQueryApi(org);
      //make query
      const query = queryBuilder(range);
      // console.log('InfluxDB query: ', query);
      await queryApi.queryRows(query, {
        next(row, tableMeta) {

          const o = tableMeta.toObject(row);
          //push rows from query into an array object
          res.push(o);
        },
        complete() {

          let finalData = []

          //variable is used to track if the current ID already has a key
          let exists = false;

          //nested for loops aren’t ideal, this could be optimized but gets the job done
          let chartDataId;
          for(let i = 0; i < res.length; i++) {

            // build chart data id
            const date = res[i]['_time'].substr(0, 10);  // "2022-07-24"
            const time = res[i]['_time'].substr(11, 5);  // "01:00:00"
            chartDataId = time;

            //check if the sensor ID is already in the finalData => Add data point to this sensor ID
            for(let j = 0; j < finalData.length; j++) {
              if(chartDataId === finalData[j]['_time']) {
                exists = true

                let point = {}
                point["x"] = date;
                point["y"] = res[i]["_value"];
                finalData[j]["data"].push(point)
              }
            } // end for

            // if the ID does not exist, create the key and append (first) data point to array
            if(!exists) {
              let d = {}
              d["id"] = chartDataId;
              d['data'] = []
              let point = {}
              point["x"] = date;
              point["y"] = res[i]["_value"];
              d['data'].push(point)
              finalData.push(d)
            }
            //need to set this back to false
            exists = false
          }

          //console.log('raw data: ', res)
          //console.log('final data: ', finalData)
          setData(finalData);

        },
        error(error) {
          // Das ist ja [gestern] nicht so gut gelaufen...
          console.error("InfluxDB query failed: ", error);

          setError(error)
        }
      });

    };

    influxQuery();
  }

  // Query data on component load...
  useEffect(() => queryInflux(range), []);

  /**
   * Change the time range and reload data / chart.
   * @param _range
   */
  function changeRange(_range) {
    // Set range time range of data
    setRange(_range);

    // Reset data and error(s)
    setData(null);
    setError(null);

    queryInflux(_range);
  }

  function rangeButtons() {
    return (
      <IonGrid>
        <IonRow size="5">
          <IonCol> &nbsp; </IonCol>
          <IonCol>
            <IonButton expand="block" onClick={ () => changeRange(RANGE_24H) } disabled={range == RANGE_24H} >
              <IonIcon icon={ range == RANGE_24H ? checkboxOutline : squareOutline} /> &nbsp;
              24 Stunden
            </IonButton>
          </IonCol>
          <IonCol>
            <IonButton expand="block" onClick={ () => changeRange(RANGE_7D) } disabled={range == RANGE_7D} >
              <IonIcon icon={ range == RANGE_7D ? checkboxOutline : squareOutline} /> &nbsp;
              1 Woche
            </IonButton>
          </IonCol>
          <IonCol>
            <IonButton expand="block" onClick={ () => changeRange(RANGE_30D) } disabled={range == RANGE_30D} >
              <IonIcon icon={ range == RANGE_30D ? checkboxOutline : squareOutline} /> &nbsp;
              1 Monat
            </IonButton>
          </IonCol>
          <IonCol> &nbsp; </IonCol>
        </IonRow>
      </IonGrid>
    )
  }

  function yAxisLegend() {
    switch (dataId) {
      case 'dataMoving': return 'Bewegungen';
      default: throw Error('dataId "' + dataId + '" not implemented!');
    }
  }

  if(error) return (
    // data=null => fetching data
    <div>
      { rangeButtons() }
      <h2 style={{ textAlign: 'center' }}>
        Fehler beim laden der Daten.
      </h2>
      <pre style={{textAlign: "center"}}>Fehler: { error.message }</pre>
    </div>
  )
  else if(data && data.length > 0) return (
    // there is data -> show chart
    // source: https://nivo.rocks/line/

    <div>
      { rangeButtons() }

      { /* Chart */ }
      <div style={{height: '450px'} /* make sure parent container have a defined height... */}>
        <ResponsiveHeatMapCanvas
          data={data}
          margin={{ top: 0, right: 85, bottom: 70, left: 125 }}
          // valueFormat=" >-.2s"
          axisTop={null}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: -90,
            legend: '',
            legendPosition: 'middle',
            legendOffset: 36
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Uhrzeit',
            legendPosition: 'middle',
            legendOffset: -60
          }}
          colors={{
            type: 'diverging',
            scheme: 'greens',
            minValue: 0,
            //maxValue: 100000,
            divergeAt: 0.5
          }}
          emptyColor="#555555"
          borderWidth={1}
          borderColor={{ theme: 'background' }}
          labelTextColor={{
            from: 'color',
            modifiers: [
              [
                'darker',
                2
              ]
            ]
          }}
          legends={[
            {
              anchor: 'right',
              translateX: 35,
              translateY: 0,
              length: 200,
              thickness: 10,
              direction: 'column',
              tickPosition: 'after',
              tickSize: 3,
              tickSpacing: 4,
              tickOverlap: false,
              tickFormat: '>-.2s',
              title: 'Bewegungen →',
              titleAlign: 'start',
              titleOffset: 4
            }
          ]}
          annotations={[]}
        />
      </div>
    </div>
  )
  else if(data) return (
    // data, but len=0 => no data...
    <div>

      { rangeButtons() }

      <h2 style={{ textAlign: 'center' }}>
        Keine Daten zum anzeigen.
      </h2>
    </div>
  )
  else return (
      // data=null => fetching data
      <div>
        <h2 style={{ textAlign: 'center' }}>
          Hole daten... <IonSpinner />
        </h2>
      </div>
    )
};

export default SecurityDataHeatmap
