import { createSlice } from '@reduxjs/toolkit';


const initialState = {
  isOn: false
}


const ttsSlice = createSlice({
  name: 'tts',
  initialState,
  reducers: {
    toggleTts: (state) => {
      state.isOn = !state.isOn;
    }
  }
});

export const { toggleTts } = ttsSlice.actions;

export default ttsSlice.reducer;