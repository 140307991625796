import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { IonButton, IonIcon } from '@ionic/react';
import { logEntryAdded, publishMqttContactRequest } from '../redux/btnlogSlice';
import { help, person, volumeHigh, caretDown, home, heart, filmOutline, informationCircle } from 'ionicons/icons';
import { Link } from 'react-router-dom';
import { setContactRequestedFalse, setContactRequestedTrue } from '../redux/contactSlice';

export function TtsSpeak(message: string) {
  var msg = new SpeechSynthesisUtterance(message);
  msg.lang = 'de-DE';
  window.speechSynthesis.speak(msg);
};



export function LogButton(props: any) {

  const dispatch = useAppDispatch();

  const isTtsOn = useAppSelector(state => state.tts.isOn);
  const lastButtonPressed = useAppSelector(state => state.btnlog.arr[state.btnlog.arr.length-1].buttonId);
  const isSecondTimePressed = (props.btnid == lastButtonPressed);

  const onSavePostClicked = () => {
    const logEntry = {
      buttonId: props.btnid,
      timestamp: new Date().toLocaleString()
    };

    dispatch(logEntryAdded(logEntry));

    
    /**  If one of the contact buttons is pressed
     * 1. send a message to the MQTT broker,
     * 2. set isContactRequested true, so a notification is shown on the page Home
     * 3. set isContactRequested false after a day has passed (86400000 ms)
     */
    if ((props.btnid).match(/Contact-[2-4]/) && (isSecondTimePressed || !isTtsOn)){
      dispatch(publishMqttContactRequest(logEntry));
      dispatch(setContactRequestedTrue());
      setTimeout(()=>{ dispatch(setContactRequestedFalse()); }, 86400000);
    }

    //Read the label of the button out loud if TTS is activated, but don't do it on the second click
    if (isTtsOn && !isSecondTimePressed){
      TtsSpeak(props.content);
    }
  }

  const loadImage = (img: string, imgclass?: string) =>{

    if (img){
      var a;
      var s;
      if (imgclass!=="menuIcon"){ s="large"; }
      switch (img) {
        case "help":
          a = help;
          break;
        case "person":
          a = person;
          break;
        case "volumeHigh":
          a = volumeHigh;
          break;
        case "caretDown":
          a = caretDown;
          break;
        case "home":
          a = home;
          break;
        case "heart":
          a = heart;
          break;
        case "filmOutline":
          a = filmOutline;
          break;
        case "informationCircle":
          a = informationCircle;
          break;
        default:
          break;
      }
      return (
        <IonIcon size={s} class={imgclass} icon={a} />
      )
    }
  }

  const getBR = (className: string) =>{
    if (className.match(/menuButton/)){
      return(<br/>);
    }
  }

  if ((typeof props.linkTo !== 'undefined') && (isSecondTimePressed || !isTtsOn)){
    return(
      <Link to={props.linkTo}><IonButton class={props.class} id={props.btnid} onClick={onSavePostClicked} expand={props.expand}><div>{loadImage(props.image, props.imageclass)}{getBR(props.class)} {props.content}</div></IonButton></Link>
    );
  } else {
    return (
      <IonButton class={props.class} id={props.btnid} onClick={onSavePostClicked} expand={props.expand}><div>{loadImage(props.image, props.imageclass)}{getBR(props.class)} {props.content}</div></IonButton>
    );
  }
}