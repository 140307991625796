////////////////////////////////////////////////////////////////////
// Shows how to use forward compatibility APIs from InfluxDB 1.8. //
////////////////////////////////////////////////////////////////////
// [InfluxDB 2.0 API compatibility endpoints](https://docs.influxdata.com/influxdb/v1.8/tools/api/#influxdb-2-0-api-compatibility-endpoints)
// are part of the InfluxDB 1.x line since InfluxDB 1.8.0. This allows you to leverage InfluxDB 2.x client libraries for both writing and
// querying data with Flux.
// https://docs.influxdata.com/influxdb/v1.8/about_the_project/releasenotes-changelog/#forward-compatibility
/**
 * Author: InfluxData
 * Source: https://github.com/influxdata/influxdb-client-js/blob/master/examples/influxdb-1.8.ts
 */

import {ClientOptions, InfluxDB} from '@influxdata/influxdb-client-browser'
import {INFLUX_BUCKET, INFLUX_PASSWORD, INFLUX_USERNAME, URL_INFLUX} from "../config";

class InfluxDBDH40 {
  private influxDB: InfluxDB;

  constructor() {
    const clientOptions: ClientOptions = {
      url: URL_INFLUX,
      token: `${INFLUX_USERNAME}:${INFLUX_PASSWORD}`,
    }

    this.influxDB = new InfluxDB(clientOptions)
  }

  writeDemo() {
    /*
    console.log('*** WRITE POINTS ***')

    const writeAPI = influxDB.getWriteApi('', bucket)
    const point = new Point('mem')
      .tag('host', 'host1')
      .floatField('used_percent', 23.43234543)
    writeAPI.writePoint(point)
    writeAPI
      .close()
      .then(() => console.log('FINISHED'))
      .catch(error => {
        console.error(error)
      })
     */
  }

  queryDemo() {
    console.log('*** QUERY ROWS ***')

    const queryAPI = this.influxDB.getQueryApi('')
    const query = `from(bucket: "${INFLUX_BUCKET}") |> range(start: -365d)   |> filter(fn: (r) => r._measurement == "mqtt_consumer" and r._field == "value")`
    queryAPI.queryRows(query, {
      next(row, tableMeta) {
        const o = tableMeta.toObject(row)
        console.log('Raw: ', JSON.stringify(o))
        console.log('Parsed: ', `${o._time} ${o._measurement} : ${o._field}=${o._value}`)
      },
      error(error) {
        console.error(error)
      },
      complete() {
        console.log('\nFinished')
      },
    })
  }
}

export default InfluxDBDH40
