import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import type { RootState, AppDispatch } from './store';
import React, { useState, useEffect, useRef } from 'react';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;


//https://github.com/donavon/use-interval/blob/master/src/index.tsx
type Delay = number | null;
type TimerHandler = (...args: any[]) => void;

export const useInterval = (callback: TimerHandler, delay: Delay) => {
    const savedCallbackRef = useRef<TimerHandler>();
  
    useEffect(() => {
      savedCallbackRef.current = callback;
    }, [callback]);
  
    useEffect(() => {
      const handler = (...args: any[]) => savedCallbackRef.current!(...args);
  
      if (delay !== null) {
        const intervalId = setInterval(handler, delay);
        return () => clearInterval(intervalId);
      }
    }, [delay]);
};