// @ts-nocheck
import React, { useState } from "react";
import { IonContent, IonHeader, IonRow, IonCol, IonText, IonButton, IonPage, IonCard, IonCardTitle, IonTitle, IonToolbar } from '@ionic/react';

import './Data.css';
import { LogButton } from '../components/LogButton';
import { useAppDispatch } from '../redux/hooks';
import { logEntryAdded } from '../redux/btnlogSlice';
import SecurityDataTable from "../components/SecurityDataTable";
import SecurityDataChart from "../components/SecurityDataChart";
import SecurityDataHeatmap from "../components/SecurityDataHeatmap";



const SecurityData: React.FC = () => {

  const [openCard, setOpenCard] = useState(-1); //number of the datacard that is shown in single view, -1 means that the overall view is shown

  const dispatch = useAppDispatch();

  /**
   * For more MQTT topics to map here, see https://mygit.th-deg.de/tcf_deinhaus40/dh40-software-and-hardware-architecture/-/blob/master/wohnung/docker/mosquitto2mosquitto/config/mosquitto.conf
   */
  const dataValues = [
    { id: 'dataDoor', subtitle: "Sicherheit", title: "Türen und Fensterkontakte", content: "...", value: "0", change: "-", measuredDetail: ["Door_state"] },
    { id: 'dataMoving', subtitle: "Sicherheit", title: "Bewegungen im Haus", content: "...", value: "0", change: "-", measuredDetail: ['Motion_sensor_status'] },
    { id: 'dataTemp', subtitle: "Sicherheit", title: "Raumtemperatur", content: "...", value: "0", change: "-", measured: ["Air_temperature"] },
    { id: 'dataPower', subtitle: "Sicherheit", title: "Intelligente Steckdosen", content: "...", value: "0", change: "-", measured: ["Power"] },
    { id: 'dataAirQualVOC', subtitle: "Sicherheit", title: "Luftqualität VOC", content: "...", value: "0", change: "-", measured: ["Volatile_Organic_Compound_level"] },
    { id: 'dataAirQualCO2', subtitle: "Sicherheit", title: "Luftqualität CO2", content: "...", value: "0", change: "-", measured: ["Carbon_dioxide_CO_level"] },
    { id: 'dataHumid', subtitle: "Sicherheit", title: "Luftfeuchtigkeit", content: "...", value: "0", change: "-", measured: ["Humidity"] },
    { id: 'dataCO', subtitle: "Sicherheit", title: "Kohlenmonoxid CO", content: "...", value: "0", change: "-", measured: ["Carbon_monoxide_CO_level"] },
    { id: 'dataBattery', subtitle: "Sicherheit", title: "Akkustände", content: "...", value: "0", change: "-", measured: ["level", "isLow"] },
  ];

  const changeDataDisplay = (text: string, id: string, num: number) => {
    setOpenCard(num);

    dispatch(logEntryAdded({
      buttonId: id,
      timestamp: new Date().toLocaleString()
    }));
  }

  // Show overview / selection
  const dataCard = (num: number) => {
    const dataTitle = dataValues[num].title;  // + title

    // Display data
    return (
      <IonCard class="DataCards"  onClick={() => changeDataDisplay(dataValues[num].title+" "+dataValues[num].value, `SecurityDataCard-${num}`, num)} key={num}>
        <IonText class="CardTitle">{ dataTitle }</IonText>
      </IonCard>
    )
  }

  // Display data in details
  const bigDataCard = (num: number) => {
    const id = dataValues[num].id;
    const dataTitle = dataValues[num].title;

    // Request data for card
    const measureds = dataValues[num].measured;
    const measuredDetails = dataValues[num].measuredDetail;

    function dataVisualization() {
      if(id === 'dataDoor' || id === 'dataBattery')
        return (<div><br/> <SecurityDataTable dataId={id} measureds={measureds} measuredDetails={measuredDetails}/> </div> ) // Show doors, motion and battery in table
      else if(id === 'dataMoving')
        return <SecurityDataHeatmap dataId={id} measureds={measureds} measuredDetails={measuredDetails}/> // Motion as heatmap
      else
        return <SecurityDataChart dataId={id} measureds={measureds} measuredDetails={measuredDetails}/>
    }

    // Display data
    return (
      <IonCard class="bigDataCard">
        <IonCardTitle class="bigCardTitle">{ dataTitle }</IonCardTitle>
        { dataVisualization() }
      </IonCard>
    )
  }


  if (openCard < 0){
    // Show Data / sensors overview + selection
    return (
      <IonPage>
        <IonHeader className="header">
          <IonToolbar>
            <IonRow>
              <IonCol size="3" class="ion-text-start">
                <LogButton linkTo="/home" btnid="SecurityData-1" class="BackButton" content="Zurück"/>
              </IonCol>
              <IonCol size="6" class="ion-text-center">
                <IonTitle id="PageTitle">Wohndaten</IonTitle>
              </IonCol>
              <IonCol size="3" class="ion-text-end">
              </IonCol>
            </IonRow>
          </IonToolbar>
        </IonHeader>
        <IonContent class="DataCardsBox" fullscreen>
          <IonRow class="DataRow">
            <IonCol size-xl="6" size-lg="12" size-md="12" size-sm="12">
              { dataValues.map(function(object, ix){
                if(ix < dataValues.length / 2)
                  return dataCard(ix);
              })}
            </IonCol>
            <IonCol size-xl="6" size-lg="12" size-md="12" size-sm="12">
              { dataValues.map(function(object, ix){
                if(ix > dataValues.length / 2)
                  return dataCard(ix);
              })}
            </IonCol>
          </IonRow>
        </IonContent>
      </IonPage>
    );
  } else {
    // Show details
    return (
      <IonPage>
        <IonHeader className="header">
          <IonToolbar>
            <IonRow>
              <IonCol size="3" class="ion-text-start">
                <IonButton id="SecurityData-1" class="BackButton" onClick={() => changeDataDisplay("Zurück", "SecurityData-1", -1)}>Zurück</IonButton>
              </IonCol>
              <IonCol size="6" class="ion-text-center">
                <IonTitle id="PageTitle">Wohndaten</IonTitle>
              </IonCol>
              <IonCol size="3" class="ion-text-end">
              </IonCol>
            </IonRow>
          </IonToolbar>
        </IonHeader>
        <IonContent class="DataCardsBox" fullscreen>
          <br/>
          { bigDataCard(openCard) }
        </IonContent>
      </IonPage>
    );
  }
};



export default SecurityData;
