// @ts-nocheck
import React, { useState, useEffect } from "react";
import { InfluxDB, ClientOptions } from "@influxdata/influxdb-client-browser";
import { area } from "d3-shape";  // required to add desired values (Normwerte) to chart
import { ResponsiveLine } from "@nivo/line";
import {IonButton, IonCol, IonGrid, IonIcon, IonRow, IonSpinner} from "@ionic/react";
import {checkboxOutline, squareOutline} from "ionicons/icons";

import { INFLUX_USERNAME, INFLUX_PASSWORD, INFLUX_BUCKET, URL_INFLUX } from "../config";

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
const SecurityDataChart = ({ dataId, measureds, measuredDetails }) => {

  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  const RANGE_24H = '-24h';
  const RANGE_7D = '-7d';
  const RANGE_30D = '-30d';
  const [range, setRange] = useState(RANGE_24H);

  function queryBuilder(range) {
    // Build filter, used in query
    let queryFilter = measureds || measuredDetails ? '|> filter(fn: (r) => ' : ''

    // add filter for measured
    if (measureds) queryFilter = queryFilter + measureds.map((m, ix, {length}) => {
      if (ix + 1 === length) return `r.measured == "${m}"`;    // Last one.
      else return `r.measured == "${m}"  or  `;     // Not last one.
    }).join('')

    // add filter for measured
    if (measuredDetails) queryFilter += measuredDetails.map((m, ix, {length}) => {
      if (ix + 1 === length) return `r.measuredDetail == "${m}"`;    // Last one.
      else return `r.measuredDetail == "${m}"  or  `;     // Not last one.
    }).join('')
    queryFilter += measureds || measuredDetails ? ')' : ''  // close bracket

    return `from(bucket: "${INFLUX_BUCKET}")
          |> range(start: ${range})
          |> filter(fn: (r) => r._measurement == "mqtt_consumer")
          |> group(columns: ["measured", "measured_detail"])
          ${queryFilter}
          |> toFloat()
          |> sort(columns: ["_time"], desc: false)`;
  }

  /**
   * Query the influx data for the given range.
   * @param range The time range.
   */
  function queryInflux(range) {
    if(range !== RANGE_24H && range !== RANGE_7D && range !== RANGE_30D) {
      throw new Error('Time range "' + range + '" not implemented.');
    }

    let res = [];
    const influxQuery = async () => {
      //create InfluxDB client
      const org = ''  // There is no org in InfluxDB v1
      const options: ClientOptions = {
        url: URL_INFLUX,
        token: `${INFLUX_USERNAME}:${INFLUX_PASSWORD}`,
      }
      const queryApi = await new InfluxDB(options).getQueryApi(org);
      //make query
      const query = queryBuilder(range);
      // console.log('InfluxDB query: ', query);
      await queryApi.queryRows(query, {
        next(row, tableMeta) {

          const o = tableMeta.toObject(row);
          //push rows from query into an array object
          res.push(o);
        },
        complete() {

          let finalData = []

          //variable is used to track if the current ID already has a key
          let exists = false;

          //nested for loops aren’t ideal, this could be optimized but gets the job done
          let chartDataId;
          for(let i = 0; i < res.length; i++) {

            // build chart data id
            chartDataId = `${ res[i]['nodeLocation'] } (${ res[i]['nodeName'] })`;

            //check if the sensor ID is already in the finalData => Add data point to this sensor ID
            for(let j = 0; j < finalData.length; j++) {
              if(chartDataId === finalData[j]['id']) {
                exists = true

                let point = {}
                const dateStr = res[i]["_time"]
                const valueStr = res[i]["_value"]
                point["x"] = new Date(dateStr);
                point["y"] = parseFloat(valueStr);
                finalData[j]["data"].push(point)
              }
            } // end for

            // if the ID does not exist, create the key and append (first) data point to array
            if(!exists) {
              let d = {}
              d["id"] = chartDataId;
              d['data'] = []
              let point = {}
              point["x"] = res[i]["_time"];
              point["y"] = res[i]["_value"];
              d['data'].push(point)
              finalData.push(d)
            }
            //need to set this back to false
            exists = false
          }

          // convert VOC from ppb to ppm
          if(dataId === 'dataAirQualVOC') {
            for(let i=0; i < finalData.length; i++) {
              const sensorData = finalData[i];
              for(let j=0; j < sensorData.data.length; j++) {
                sensorData.data[j].y *= 1000;  // convert ppb to ppm (parts per billion TO million
              }
            }
          }

          //console.log('raw data: ', res)
          //console.log('final data: ', finalData)
          setData(finalData);

        },
        error(error) {
          // Das ist ja [gestern] nicht so gut gelaufen...
          console.error("InfluxDB query failed: ", error);

          setError(error)
        }
      });

    };

    influxQuery();
  }

  // Query data on component load...
  useEffect(() => queryInflux(range), []);

  /**
   * Change the time range and reload data / chart.
   * @param _range
   */
  function changeRange(_range) {
    // Set range time range of data
    setRange(_range);

    // Reset data and error(s)
    setData(null);
    setError(null);

    queryInflux(_range);
  }

  /**
   * Display buttons to change the interval of the cart. E.g. [ 24 hours ], [ 1 week ], ...
   */
  function rangeButtons() {
    return (
      <IonGrid>
        <IonRow size="5">
          <IonCol> &nbsp; </IonCol>
          <IonCol>
            <IonButton expand="block" onClick={ () => changeRange(RANGE_24H) } disabled={range == RANGE_24H} >
              <IonIcon icon={ range == RANGE_24H ? checkboxOutline : squareOutline} /> &nbsp;
              24 Stunden
            </IonButton>
          </IonCol>
          <IonCol>
            <IonButton expand="block" onClick={ () => changeRange(RANGE_7D) } disabled={range == RANGE_7D} >
              <IonIcon icon={ range == RANGE_7D ? checkboxOutline : squareOutline} /> &nbsp;
              1 Woche
            </IonButton>
          </IonCol>
          <IonCol>
            <IonButton expand="block" onClick={ () => changeRange(RANGE_30D) } disabled={range == RANGE_30D} >
              <IonIcon icon={ range == RANGE_30D ? checkboxOutline : squareOutline} /> &nbsp;
              1 Monat
            </IonButton>
          </IonCol>
          <IonCol> &nbsp; </IonCol>
        </IonRow>
      </IonGrid>
    )
  }

  /**
   * Get the Y axis legend for the current data (id)
   */
  function yAxisLegend() {
    switch (dataId) {
      case 'dataTemp': return 'Temperatur in °C';
      case 'dataPower': return 'Strom in Watt';
      case 'dataAirQualVOC': return 'Volatile Organic Compound in ppm';
      case 'dataAirQualCO2': return 'Kohlendioxid (CO₂) in ppm';
      case 'dataHumid': return 'Luftfeuchtigkeit in %';
      case 'dataCO': return 'Carbon Monoxide (CO) in ppm';
      default: throw Error('dataId "' + dataId + '" not implemented!');
    }
  }

  /**
   * Create the area layer for the chart to indicate good and bad sensor data ranges.
   * E.g. CO2: < 800ppm -> green
   */
  const AreaLayer = ({ series, xScale, yScale, innerHeight }) => {
    // Only display for CO2 and VOC
    if(dataId !== 'dataAirQualCO2' && dataId !== 'dataAirQualVOC')
      return null;

    const areaValues = {
      // for colors see References.css -> .Great, .Good, .Average, .Mediocre, ...
      'dataAirQualCO2': [ {min: 0, max: 800, color: '#70cb79'}, {min: 800, max: 1000, color: '#e6f065'}, {min: 1000, max: 1400, color: '#e3bb5f'}, {min: 1400, max: 2000, color: '#e08059'}, {min: 2000, max: 10_000_000, color: '#e44d52'} ],
      'dataAirQualVOC': [ {min: 0, max: 500, color: '#70cb79'}, {min: 500, max: 1000, color: '#e6f065'}, {min: 1000, max: 1600, color: '#e3bb5f'}, {min: 1600, max: 2200, color: '#e08059'}, {min: 2200, max: 10_000_000, color: '#e44d52'} ],
    }

    const areas = [];
    areaValues[dataId].map(areaValue => {  // create one area for each pair of min/max quality values
      const areaGenerator = area()
        .x(d => xScale(d.data.x))             // Position of both line breaks on the X axis
        .y0(d => yScale(areaValue.min))                 // Y position of bottom line breaks; (0,0) is top left;  there is no overflow at bottom of chart
        .y1(d => Math.max(yScale(areaValue.max), 0) )   // Y position of top line breaks;  Math.max(_,_) avoids overflow at top of chart

      const a = <path
        d={areaGenerator(series[0].data)}  // use the first series (here: sensor) of data
        fill={areaValue.color}
        style={{mixBlendMode: "multiply", pointerEvents: "none"}}
        opacity={0.2}
      />
      areas.push(a);
    })
    return areas;
  };

  if(error) return (
    // data=null => fetching data
    <div>
      { rangeButtons() }
      <h2 style={{ textAlign: 'center' }}>
        Fehler beim laden der Daten.
      </h2>
      <pre style={{textAlign: "center"}}>Fehler: { error.message }</pre>
    </div>
  )
  else if(data && data.length > 0) return (
    // there is data -> show chart
    // source: https://nivo.rocks/line/

    <div>
      { rangeButtons() }

      { /* Chart */ }
      <div style={{height: '450px'} /* make sure parent container have a defined height... */}>
        <ResponsiveLine
          data={data}
          margin={{ top: 50, right: 60, bottom: 50, left: 60 }}
          xScale={{ format: '%Y-%m-%dT%H:%M:%S.%L%Z', type: 'time' }}
          yScale={{ type: 'linear',
            stacked: false,
            min: ( dataId === 'dataAirQualCO2' || dataId === 'dataAirQualVOC' ? 0 : 'auto' ),  // min=0 if CO2 or VOC for quality indicators (see AreaLayer)
            max: 'auto'
          }}
          yFormat=' >-.2f'
          curve='monotoneX'
          axisTop={null}
          axisRight={null}
          axisBottom={{
            // tickValues: 'every 15 minute',
            tickSize: 5,
            tickPadding: 10,
            tickRotation: -15,
            format: '%d.%m.%Y %H:%M:%S',
            /* legend: 'Zeit', */
            legendOffset: 36,
            legendPosition: 'middle'
          }}
          axisLeft={{
            orient: 'left',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            format: '.2s',
            legend: yAxisLegend(),
            legendOffset: -50,
            legendPosition: 'middle'
          }}
          enableGridX={true}
          enableGridY={true}
          colors={{ scheme: 'category10' }}
          lineWidth={2}
          pointSize={5}
          pointColor={{ theme: 'background' }}
          pointBorderWidth={1}
          pointBorderColor={{ from: 'serieColor' }}
          pointLabelYOffset={-12}
          layers={[ // default: ['grid', 'markers', 'axes', 'areas', 'crosshair', 'lines', 'points', 'slices', 'mesh', 'legends']
            'grid', 'markers', 'axes', 'areas', 'crosshair', 'lines', 'points', 'slices', 'mesh', 'legends',
            AreaLayer,
          ]}
          useMesh={true}
          legends={[
            {
              anchor: 'top',
              direction: 'row',
              justify: false,
              translateX: 0,
              translateY: -40,
              itemsSpacing: 2,
              itemDirection: 'top-to-bottom',
              itemWidth: 150,
              itemHeight: 12,
              itemOpacity: 0.75,
              symbolSize: 12,
              symbolShape: 'circle',
              symbolBorderColor: 'rgba(0, 0, 0, .5)',
              effects: [
                {
                  on: 'hover',
                  style: {
                    itemBackground: 'rgba(0, 0, 0, .03)',
                    itemOpacity: 1
                  }
                }
              ]
            }
          ]}
        />
      </div>
    </div>
  )
  else if(data) return (
    // data, but len=0 => no data...
    <div>

      { rangeButtons() }

      <h2 style={{ textAlign: 'center' }}>
        Keine Daten zum anzeigen.
      </h2>
    </div>
  )
  else return (
    // data=null => fetching data
    <div>
      <h2 style={{ textAlign: 'center' }}>
        Hole daten... <IonSpinner />
      </h2>
    </div>
  )
};

export default SecurityDataChart
