import React from "react";
import {IonContent, IonHeader, IonRow, IonCol, IonButton, IonPage, IonTitle, IonToolbar, IonImg } from '@ionic/react';
import './Data.css';
import { LogButton } from '../components/LogButton';
import { WITHINGS_HEALTH_MATE_APP_LINK } from "../config";


const HealthData: React.FC = () => {

  /**
   * Determine the mobile operating system.
   * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
   *
   * @returns {String}
   */
  function getMobileOperatingSystem() {
    // @ts-ignore
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    // @ts-ignore
    if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
    }

    // @ts-ignore
    if (/android/i.test(userAgent)) {
      return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    // @ts-ignore
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
    }

    return "unknown";
  }

  function isAndroid() {
    return getMobileOperatingSystem() === 'Android';
  }

  function notifyOnlyAndroid() {
    if (!isAndroid()) {
      alert('this link only works on Android!')
    }
  }

  return (
    <IonPage>
      <IonHeader className="header">
        <IonToolbar>
          <IonRow>
            <IonCol size="3" class="ion-text-start">
              <LogButton linkTo="/home" btnid="HealthData-1" class="BackButton" content="Zurück"/>
            </IonCol>
            <IonCol size="6" class="ion-text-center">
              <IonTitle id="PageTitle">Gesundheitsdaten</IonTitle>
            </IonCol>
            <IonCol size="3" class="ion-text-end">
            </IonCol>
          </IonRow>
        </IonToolbar>
      </IonHeader>
      <IonContent class="DataCardsBox" fullscreen>
        <IonRow class="DataRow" style={{background: 'url("assets/img/withings/devices-api.jpg") 0 0/100% auto no-repeat', height: '95%'}}>
          <IonCol size="6" style={{textAlign: 'center'}}>

            <p>&nbsp;</p>

            <h2 style={{ margin: 0 }}>Die Gesundheitsdaten können in der <br/>
              Withings App angesehen werden.</h2>

            <p>&nbsp;</p>

            <h1 style={{ fontSize: '200%', margin: 0 }}>
              <img src="assets/img/withings/withings-logotype-black.svg" alt="Withings Logo" height="80px" style={{ verticalAlign: 'middle', paddingBottom: '5px' }}/>

              <a href={ WITHINGS_HEALTH_MATE_APP_LINK } onClick={ notifyOnlyAndroid }>
                <IonImg src="assets/img/withings/logo-withings-health-mate--app.webp" style={{ height: '75px' }} />
                <h4 style={{paddingTop: '0', marginTop: '0'}}>Health Mate</h4>
              </a>
            </h1>

            <p>&nbsp;</p>

            { /* Open Withings Health Mate app -> see https://stackoverflow.com/a/2958870/1700776 */ }
            <IonButton href={ WITHINGS_HEALTH_MATE_APP_LINK } onClick={ notifyOnlyAndroid }>
              Withings Health Mate App Öffnen &nbsp;
              <IonImg src="assets/img/withings/logo-withings-health-mate-transparent.webp" style={{height: '50px', padding: '5px'}} />
            </IonButton>

          </IonCol>
          <IonCol size="6" style={{textAlign: 'center'}}>
          </IonCol>
        </IonRow>
      </IonContent>
    </IonPage>
  );
};


export default HealthData;
