import { IonContent, IonHeader, IonRow, IonCol, IonButton, IonPage, IonTitle, IonToolbar, IonFooter } from '@ionic/react';
import { createRef } from 'react';
import {useHistory, useParams} from 'react-router';
import ReactPlayer from 'react-player';
import { logEntryAdded } from '../redux/btnlogSlice';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import './Training.css';
import {GROUP_DEFAULT_ID} from "../redux/configSlice";

function TrainingComponent() {

  // Get the video from video-URL
  // @ts-ignore
  let { videoid } = useParams();
  console.log('videoid: ', videoid);

  const history = useHistory();
  const dispatch = useAppDispatch();

  // Get and derive values from Redux store
  const homeId = useAppSelector(state => state.config.homeId);
  const groupId = useAppSelector(state => state.config.groupId);
  const weekOfGroup = useAppSelector(state => state.config.weekOfGroup);

  const isArchiveShown = useAppSelector(state => state.archive.isArchiveShown);
  const archiveButtonClass = (isArchiveShown ? "" : " hiddenElement");

  const questionsAnswered = useAppSelector(state => state.archive.isSurveyAnswered);

  const media: React.RefObject<HTMLVideoElement> = createRef();

  const isSurveyAnswered = () => {
    const counter = questionsAnswered.filter((question: any) => !!question).length
    return counter === 3;
  }

  const clickOnVideo = () => {
    if (media.current !== null) {
      if (media.current.paused) {
        media.current.play();
      } else {
        media.current.pause();
      }
    }
  }

  const onButtonClick = (linkname: string, btnid: string, text: string) => {
    if (media.current !== null) {
      media.current.pause();
    }
    logBtnClick(btnid);
    history.push(linkname);
  }

  const logBtnClick = (btnid: string) => {
    const logEntry = {
      buttonId: btnid,
      timestamp: new Date().toLocaleString()
    };
    dispatch(logEntryAdded(logEntry));
  }

  /**
   * Get the video source per week number:
   *   0 -> Welcome Video
   *   1 -> Introduction Video
   *   2 -> ...
   * @param week: int
   * @return string The relative URL to the current video.
   */
  const videoPerWeek = (week: number) => {
    switch (week) {
      case 0:
        return "/assets/video/Videobotschaft_Kunhardt.mp4";
      case 1:
        return "/assets/video/1-Schulung_Einfuehrung.mp4";
      case 2:
        return "/assets/video/3-Schulung_Gesundheitstechnik.mp4";
      case 3:
        return "/assets/video/4-Schulung_Schlaf.mp4";
      case 4:
        return "/assets/video/5-Schulung_Bewegung.mp4";
      case 5:
        return "/assets/video/6-Schulung_Sturzpraevention.mp4";
      case 6:
        return "/assets/video/7-Schulung_Sturzgeschehen.mp4";
      case 7:
        return "/assets/video/2-Schulung_Sicherheitstechnik.mp4";
      case 8:
        return "/assets/video/8-Schulung_Zukunftsvorsorge.mp4";
      case 9:
        return "/assets/video/9-Schulung_Sozialvernetzung_sm.mp4";
      case 10:
        return "/assets/video/10-Schulung_Zuversicht_sm.mp4";
      default:
        console.warn('Week', week, 'not found! Returning default video.');
        return "assets/video/Videobotschaft_Kunhardt.mp4";
    }
  }

  /**
   * Select the video source for the video on the page.
   * - If coming from archive, the videoid from the URL used - also uses ``videoPerWeek(videoId)`` to map VideoID to an URL.
   * - If the current video should be displayed "homeId" > "group" > "group week" determines the displayed video.
   *
   * @return string The relative video source
   */
  const videoSource = () => {
    /*
     * Select video depending on the videoid of the route props
     */
    if(videoid) {
      const weekFromVideoId = parseInt(videoid);
      return videoPerWeek(weekFromVideoId);  // Show video from video ID // TODO Do not misuse videoPerWeek for this!
    }

    /*
     * Select video depending on the participant's group
     */
    // Is group undefined -> default group => default video
    if(groupId === GROUP_DEFAULT_ID) {
      console.warn('Using GROUP_DEFAULT as group');
      return videoPerWeek(-1);  // getting default video
    }
    // Week (since start) of group
    const week = weekOfGroup ?? 0;
    const weekStart = Math.floor(week);  // Play the video for the whole (numbered) week.

    // Video per week
    const videoSource = videoPerWeek(weekStart);

    console.log('home id: ', homeId, ';   group: ', groupId, ';   week: ', week);  // log infos
    return videoSource
  }
  const videoSourceUrl = videoSource();

  return (
    <IonPage>
      <IonHeader class="header">
        <IonToolbar>
          <IonRow>
            <IonCol size="3" class="ion-text-start">
              {/*If the current survey is already answered you get redirected to /home, else you get redirected to/survey */}
              <IonButton class="BackButton" id="Training-1" onClick={() => {
                onButtonClick((isSurveyAnswered() ? "/home" : "/survey"), "Training-1", "Zurück")
              }}>Zurück</IonButton>
            </IonCol>
            <IonCol size="6" class="ion-text-center">
              <IonTitle id="PageTitle">Aktuelle Schulung</IonTitle>
            </IonCol>
            <IonCol size="3" class="ion-text-end">
              <IonButton class={"ArchiveButton" + archiveButtonClass} id="Training-2" onClick={() => {
                onButtonClick("/archive", "Training-2", "Schulungs-Archiv")
              }}>Schulungs-Archiv</IonButton>
            </IonCol>
          </IonRow>
        </IonToolbar>
      </IonHeader>
      <IonContent class="MenuContainer" fullscreen>
        <div className="controls">
          <IonButton class="videoOverlay" onClick={() => clickOnVideo()} />
        </div>
        <ReactPlayer url={ videoSourceUrl } width="100%" height="100%" controls={true} />
        { (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') ? <div><pre>Video: { videoSourceUrl }</pre></div> : null /* Display Video in DEV env */ }
      </IonContent>
      <IonFooter class="footer">
        <IonRow>
          <IonCol class="ion-text-center">
          </IonCol>
        </IonRow>
      </IonFooter>
    </IonPage>
  );
}

const Training: React.FC = () => {
  return (
    <TrainingComponent/>
  );
};

export default Training;
