// @ts-nocheck  // TODO undo ignore Typescript

import React, {useEffect, useState} from "react";
import { IonContent, IonHeader, IonButton, IonPage, IonTitle, IonToolbar, IonRow, IonCol, IonText, IonIcon, IonImg, IonSpinner } from '@ionic/react';
import { useSelector, useDispatch } from 'react-redux'

import './Development.css';
import { LogButton } from '../components/LogButton';
import { getHomeId, fetchHomeId, getWithingsConfig, fetchWithingsConfig } from '../redux/configSlice'

/* MQTT */
//import { mqttClient } from '../mqtt/mqttService'
import {InfluxDB} from "@influxdata/influxdb-client-browser";
import InfluxDBDH40 from "../components/influxdb-1.8";
import {inspect} from "util";
import colors = module

const Development = () => {
  const dispatch = useDispatch()

  const publishMqttMessage = async (topic: string, message: string | Buffer) => {
    await mqttClient.publish(topic, message, () => {
      console.log('Published: ', topic)  // TODO what do?
    })
  }

  const homeId = useSelector(getHomeId)
  const statusHomeId = useSelector((state) => state.config.statusHomeId)
  const errorHomeId = useSelector((state) => state.config.errorHomeId)

  const withingsConfig = useSelector(getWithingsConfig)
  const statusWithingsConfig = useSelector((state) => state.config.statusWithingsConfig)
  const errorWithingsConfig = useSelector((state) => state.config.errorWithingsConfig)

  const [mqttMessages, setMqttMessages] = useState([]);
  const [topicInput, setTopicInput] = useState('dh40-app');

  // fetch Home ID
  useEffect(() => {
    if (statusHomeId === 'idle') {
      dispatch(fetchHomeId())
    }
  }, [statusHomeId, dispatch])

  // fetch Withings config
  useEffect(() => {
    if (statusWithingsConfig === 'idle') {
      dispatch(fetchWithingsConfig())
    }
  }, [statusWithingsConfig, dispatch])

  let contentHomeId
  if (statusHomeId === 'loading') {
    contentHomeId = <ion-spinner/>
  } else if (statusHomeId === 'succeeded') {
    contentHomeId = <code>{ homeId }</code>
  } else if (statusHomeId === 'failed') {
    contentHomeId = <code style={{color: "red"}}>{ errorHomeId }</code>
  }


  let contentWithingsConfig
  if (statusWithingsConfig === 'loading') {
    contentWithingsConfig = <ion-spinner/>
  } else if (statusWithingsConfig === 'succeeded') {
    contentWithingsConfig = <pre>{ JSON.stringify(withingsConfig, null, 2) }</pre>
  } else if (statusWithingsConfig === 'failed') {
    contentWithingsConfig = <code style={{color: "red"}}>{ errorWithingsConfig }</code>
  }

  const changedTopicInput = function (e) {
    setTopicInput(e.target.value);
  }

  const publish = function (topic, message) {
    const PUB_TOPIC_TEST = 'dh40-app'
    const PUB_MESSAGE_TEST = 'Hello from the DH40 App.'

    topic = topicInput ?? PUB_TOPIC_TEST
    message = message ?? PUB_MESSAGE_TEST
    publishMqttMessage(topic, message);
  }

  const queryInflux = function () {
    const influxDB = new InfluxDBDH40()
    influxDB.queryDemo()
  }

  const getButtonLog = function () {
    const localStorageKEY = "persist:deinhaus"

    // Load from Local Storage
    const localStorageRaw = localStorage.getItem(localStorageKEY)
    const localStorageObj = JSON.parse(localStorageRaw)

    // Parse button log array
    const buttonLog = JSON.parse( localStorageObj.arr )

    // Pretty format button log
    const localStorageStr = JSON.stringify(buttonLog, null, 2)
    return localStorageStr
  }

  return (
    <IonPage>
      <IonHeader class="header">
        <IonToolbar>
          <IonRow>
            <IonCol size="3" class="ion-text-start">
              <LogButton linkTo="/home" btnid="Development-1" class="BackButton" content="Zurück"/>
            </IonCol>
            <IonCol size="6" class="ion-text-center">
              <IonTitle id="PageTitle"><code>Development</code></IonTitle>
            </IonCol>
            <IonCol size="3" class="ion-text-end">
            </IonCol>
          </IonRow>
        </IonToolbar>
      </IonHeader>
      <IonContent id="" class="MenuContainer" fullscreen>
        { /* Stats */ }
        <IonRow id="ContactRow">
          <IonCol size="12">
            <h1>Haushalts ID: { contentHomeId }</h1>
            <h1>Withings User ID: { contentWithingsConfig }</h1>
            <h2>Development build: { (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') ? '🔥 (dev)' : '🚀 (production)' }</h2>
          </IonCol>
        </IonRow>

        <IonRow>
          <IonCol size="12">
            <h1>InfluxDB</h1>
            <p>
              <button onClick={ queryInflux }>Query InfluxDB</button> &nbsp;
              (View result in Console.)
            </p>
          </IonCol>
        </IonRow>

        <IonRow>
          <IonCol size="12">
            <h1>MQTT.js <small style={{color: "red"}}>Broken by now!</small></h1>
            { /*
            <p>
              <button onClick={ publish }>Publish Message</button> &nbsp;
              Topic: <input type="text" value={topicInput} onChange={changedTopicInput} style={{fontFamily: "monospace"}}/>
            </p>
            <div>
              MQTT Messages:
              <pre>{ JSON.stringify(mqttMessages, null, 2) } </pre>
            </div>
            */ }
          </IonCol>
        </IonRow>

        <IonRow>
          <IonCol size="12">
            <h1>Full Button Log <small>from Local Storage</small></h1>
            <pre style={{ maxHeight: '75vh', border: "solid 1px" }}>{ getButtonLog() }</pre>
          </IonCol>
        </IonRow>

      </IonContent>
    </IonPage>
  );
}

export default Development
