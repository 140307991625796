import React, { useState } from "react";
import { IonContent, IonHeader, IonRow, IonCol, IonText, IonIcon, IonButton, IonPage, IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent, IonTitle, IonToolbar, IonSlides, IonLabel } from '@ionic/react';
import './FAQ.css';
import { LogButton, TtsSpeak } from '../components/LogButton';
import { alertCircleOutline } from 'ionicons/icons';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { logEntryAdded } from '../redux/btnlogSlice';
import { TtsIonText } from "../components/TtsIonText";


const title = [
  "Ihre Körperwaage macht Probleme?",
  "Sie haben Probleme mit der Armbanduhr?",
  "Der Sensor blinkt, was soll ich tun?",
  "Meine Schlafmatte funktioniert nicht.",
  "Darf ich den Mini-Computer ausstecken?",
  "Darf ich den Router ausstecken?",
  "Die Schlafmatte vibriert."
];


const FAQ: React.FC = () => {

  const [openCard, setOpenCard] = useState(-1); //number of the datacard that is shown in single view, -1 means that the overall view is shown 

  const dispatch = useAppDispatch();
  const isTtsOn = useAppSelector(state => state.tts.isOn);
  const [lastButtonPressed, setLastButtonPressed] = useState(useAppSelector(state => state.btnlog.arr[state.btnlog.arr.length-1].buttonId)); 


  const changeDataDisplay = (text: string, id: string, num: number) => {
    if (isTtsOn && lastButtonPressed!== id){
      TtsSpeak(text);
    } else {
      setOpenCard(num);
    }

    dispatch(logEntryAdded({
      buttonId: id,
      timestamp: new Date().toLocaleString()
    }));
    setLastButtonPressed(id);
  }

  const readText = (event: any) => {
    if (isTtsOn){
      TtsSpeak(event.target.innerText);
    }
  }
   
  //dataCards in overall view
  var dataCard = (num: number) => {
    return (
      <IonCard class="DataCards"  onClick={() => changeDataDisplay(title[num], `FAQCard-${num}`, num)}>
      <IonText class="CardTitle">{title[num]}</IonText>
      </IonCard>
    )
  }

  //datacard in single view mode
  var bigDataCard = (num: number) => {
    switch (num) {
        case 0:
          //Ihre Körperwaage macht Probleme? 
          return (
            <IonRow class="ReferenceRow">
              <IonLabel>Die Waage zeigt keine oder unvollständige Werte:</IonLabel>
              <ul>
                <li>
                  <b>Oberfläche der Waage prüfen</b>
                  <p>Da die Waage äußerst empfindlich ist, können bereits leichte Verunreinigungen, wie etwa Tierhaare oder Staub dazu führen, dass die Messung nicht mehr korrekt durchgeführt werden kann.</p>
                </li>
                <li>
                  <b>Tragen Sie Socken oder Schuhwerk?</b>
                  <p>Für eine korrekte Messung benötigt die Waage direkten Körperkontakt zu Ihren beiden Füßen. Bitte ziehen Sie daher Socken und Schuhe vor dem Wiegen aus.</p>
                </li>
                <li>
                  <b>Kalte oder nasse Füße? </b>
                  <p>Die Waage braucht optimalen Hautkontakt, daher kann es bei nassen oder kalten Füßen zu Messfehlern oder einem Abbruch der Messungen kommen. Sollten Sie zuvor über kaltem Boden gegangen sein, versuchen Sie beim nächsten Mal eine Messung, indem sie zu nächsten mit Hausschuhen oder Socken zur Waage gehen und diese dann erst direkt bei der Waage ausziehen. </p>
                </li>
                <li>
                  <b>Stehen Sie gerade?</b>
                  <p>In den Ecken des Displays der Waage erscheinen vor Messbeginn ggf. kleine, weiße Pfeile bzw. Dreiecke. Diese zeigen an, ob Sie korrekt auf der Waage stehen oder eventuell mehr Gewicht in eine Richtung verlagern. Wenn Sie geradestehen, verschwinden die Pfeile, der Display blinkt und die Messung startet. Bitte verlassen Sie die Waage erst, wenn das Display wieder schwarz geworden ist. Ansonsten kann es zu Übertragungsfehlern kommen.</p>
                </li>
                <li>
                  <b>Keine hilfreichen Informationen dabei? </b>
                  <p>Dann kontaktieren Sie uns!</p>
                </li>
              </ul>
            </IonRow>
          );
          break;
        case 1:
          //Sie haben Probleme mit der Armbanduhr? 
          return (
            <IonRow class="ReferenceRow">
              <IonLabel>Meine Uhr zeigt keine Herzfrequenz an</IonLabel>
              <p>Wenn Ihre Uhr keine Daten zur Herzfrequenz anzeigt oder sich noch in der Messung befindet, kann dies verschiedene Gründe haben. Entweder, Sie haben die Uhr gerade erst neu angelegt, in diesem Fall dauert es einige Minuten, bis ein aktueller Wert gemessen und angezeigt wird oder es stimmt etwas an der Position der Uhr nicht. In letzterem Fall nehmen Sie die Uhr bitte ab und legen diese erneut am Handgelenk an. Bitte achten Sie darauf, dass die Uhr nicht zu locker am Handgelenk angelegt ist. Wenn die Uhr zu locker ist werden die Messwerte durch seitlich einfallendes Licht verfälscht oder eine Messung ist nicht möglich.</p>
              <IonLabel>Die Uhr zeigt ungewöhnlich viele Bewegungen an. Was ist der Grund?</IonLabel>
              <p>Während Sie Ihre Uhr am Handgelenk tragen werden alle Bewegungen aufgezeichnet, welche Sie mit den Armen machen. Hierzu zählen nicht nur Bewegungen während des Gehens, sondern auch andere Tätigkeiten, für die Sie Ihre Arme benutzen wie beispielsweise Sport oder Dehnungsübungen, Haushaltstätigkeiten und Weiteres. Aus diesem Grund kann es, dass die Anzahl Ihrer Bewegungen stark vom Vortag abweichen kann. Je nachdem, was Sie an diesem Tag so erlebt haben. Grundsätzlich kann man sagen: je höher die Anzahl Ihrer Bewegungen, desto gesünder ist dies für Sie.</p>
              <IonLabel>Die Uhr zeigt eine falsche Uhrzeit an. Was kann ich tun?</IonLabel>
              <p>Es kann unter Umständen dazu kommen, dass die von Ihrer Uhr angezeigt die Uhrzeit falsch ist. Dies ist unter anderem der Fall, wenn die Uhr bei schwachem Akku nicht erneute aufgeladen wird und komplett leer ist. Um Ihre Uhrzeit bei Bedarf selbst einzustellen gehen Sie mit den Bedienknöpfen auf die Seite "Uhrzeit einstellen" und drücken Sie den mittleren Knopf. Hier können Sie nun zunächst die Stunde mit dem oberen und unteren Knopf nach oben oder nach unten korrigieren. Stimmt die Stunde drücken Sie den mittleren Knopf und stellen anschließend die Minuten genauso ein. Sind die Minuten ebenfalls richtig eingestellt, können Sie durch drücken des mittleren Knopfes die Uhrzeit speichern und gelangen wieder auf die Startseite.</p>
            </IonRow>
            );
          break;
        case 2:
          //Der Sensor blinkt, was soll ich tun?
          return (
            <IonRow class="ReferenceRow">
              Blinken ist bei den meisten unserer Sensoren ganz normal. Sie müssen sich keine Sorgen machen, dies zeigt nur, dass der Sensor funktioniert.
            </IonRow>
          );
          break;
        case 3:
          //Meine Schlafmatte funktioniert nicht.
          return (
            <IonRow class="ReferenceRow">
              Bitte bedenken Sie, dass die Schlafmatte bei unserem Technik-Set eine der Ausnahmen darstellt, welche dauerhaft mit Strom versorgt werden müssen. 
            </IonRow>
          );
          break;
        case 4:
          //Darf ich den Mini-Computer ausstecken?
          return (
            <IonRow class="ReferenceRow">
              Aus Sicherheitsgründen würden wir davon abraten, dass Sie die Systeme stromlos setzen, da somit unsere Datenübertragung beeinträchtigt wird und eventuell keine Daten mehr gesendet werden können, wenn das System erneut eingesteckt wird.
            </IonRow>
          );
          break;
          case 5:
            //Darf ich den Router ausstecken?
            return (
              <IonRow class="ReferenceRow">
                Aus Sicherheitsgründen würden wir davon abraten, dass Sie die Systeme stromlos setzen, da somit unsere Datenübertragung beeinträchtigt wird und eventuell keine Daten mehr gesendet werden können, wenn das System erneut eingesteckt wird.
              </IonRow>
            );
            break;
        case 6:
          //Die Schlafmatte vibriert.
          return (
            <IonRow class="ReferenceRow">
              Dies tritt meistens nach einem Neustart oder einem Update auf. Sollte dies nach kurzer Zeit wieder verschwinden, ist das kein Problem.
            </IonRow>
          );
          break;
        default:
          return (
            <TtsIonText content="Es ist ein Fehler aufgetreten. Wir entschuldigen uns für die Unannehmlichkeiten." />
          )
          break;
    }
  }
  

  if (openCard < 0){
    return (
      <IonPage>
          <IonHeader className="header">
          <IonToolbar>
              <IonRow>
                <IonCol size="3" class="ion-text-start">
                <LogButton linkTo="/generally" btnid="FAQ-1" class="BackButton" content="Zurück"/>
                </IonCol>
                <IonCol size="6" class="ion-text-center">
                <IonTitle id="PageTitle">Hilfestellung</IonTitle>
                </IonCol>
                <IonCol size="3" class="ion-text-end">
                </IonCol>
              </IonRow>
              </IonToolbar>
          </IonHeader>
          <IonContent class="DataCardsBox" fullscreen>
          <IonRow class="DataRow">
          <IonCol size="12">
            <IonCard id="NoticeCard">
              <IonIcon icon={alertCircleOutline} size="large"/> <IonLabel>Hinweis</IonLabel>
              <p>Die Teilnehmer der Studie werden in vier Gruppen unterteilt. Die Zuteilung der Probanden zu einer dieser Gruppen erfolgt per Zufallsprinzip. Je nachdem, welcher Gruppe Sie zugeordnet wurden, erhalten Sie manche Techniken erst zu einem späteren Zeitpunkt. Daher kann es möglich sein, dass in der nachfolgenden Hilfestellung Geräte erwähnt werden, welche Sie zum aktuellen Zeitpunkt noch nicht besitzen.</p>
            </IonCard>
          </IonCol>
            <IonCol size="12">
              {dataCard(0)}
              {dataCard(1)}
              {dataCard(2)}
              {dataCard(3)}
              {dataCard(4)}
              {dataCard(5)}
              {dataCard(6)}
            </IonCol>
          </IonRow>
          </IonContent>
      </IonPage>
    );
  } else {
  return (
    <IonPage>
        <IonHeader className="header">
          <IonToolbar>
            <IonRow>
              <IonCol size="3" class="ion-text-start">
              <IonButton id="FAQ-1" class="BackButton" onClick={() => changeDataDisplay("Zurück", "FAQ-1", -1)}>Zurück</IonButton>
              </IonCol>
              <IonCol size="6" class="ion-text-center">
              <IonTitle id="PageTitle">Hilfestellung</IonTitle>
              </IonCol>
              <IonCol size="3" class="ion-text-end">
              </IonCol>
            </IonRow>
          </IonToolbar>
        </IonHeader>
        <IonContent class="DataCardsBox" fullscreen>
          <br/><IonCard class="bigDataCard"  onClick={(event) => readText(event)}>
            <IonCardTitle class="bigCardTitle">{title[openCard]}</IonCardTitle>
            {bigDataCard(openCard)}
          </IonCard>
        </IonContent>
    </IonPage>
  );
}
};



export default FAQ;
