import React from 'react';
import { IonText, withIonLifeCycle } from '@ionic/react';

class Clock extends React.Component {

  state={ day: null, time: null}

  componentDidMount() {
  
    var d = new Date();
    var weekday = new Array(7);
    weekday[0] = "Sonntag";
    weekday[1] = "Montag";
    weekday[2] = "Dienstag";
    weekday[3] = "Mittwoch";
    weekday[4] = "Donnerstag";
    weekday[5] = "Freitag";
    weekday[6] = "Samstag";
  
    var n = weekday[d.getDay()];
    
    this.setState({ day: n})

    setInterval(() => {
      this.setState({
        time : new Date().toLocaleString()
      })
    }, 1000)
  }
  componentWillUnmount() {
    //clearInterval();
  }

  render() {
    return (
      <IonText>
          <b>{this.state.day}</b>, {this.state.time} Uhr
      </IonText>
    );
  }
}

export default withIonLifeCycle(Clock);
