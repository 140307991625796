import React, { useState } from "react";
import { IonContent, IonHeader, IonRow, IonCol, IonText, IonIcon, IonButton, IonPage, IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent, IonTitle, IonToolbar, IonSlides } from '@ionic/react';
import './Reference.css';
import { LogButton, TtsSpeak } from '../components/LogButton';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { logEntryAdded } from '../redux/btnlogSlice';
import { TtsIonText } from "../components/TtsIonText";


const title = [
  "Luftgütesensor - VOC-Schwellenwerte",
  "Luftgütesensor - Kohlendioxid-Schwellenwerte",
  "Kohlenmonoxid-Melder"
];


const ReferenceSecurityData: React.FC = () => {

  const [openCard, setOpenCard] = useState(-1); //number of the datacard that is shown in single view, -1 means that the overall view is shown

  const dispatch = useAppDispatch();
  const isTtsOn = useAppSelector(state => state.tts.isOn);
  const [lastButtonPressed, setLastButtonPressed] = useState(useAppSelector(state => state.btnlog.arr[state.btnlog.arr.length-1].buttonId));


  const changeDataDisplay = (text: string, id: string, num: number) => {
    if (isTtsOn && lastButtonPressed!== id){
      TtsSpeak(text);
    } else {
      setOpenCard(num);
    }

    dispatch(logEntryAdded({
      buttonId: id,
      timestamp: new Date().toLocaleString()
    }));
    setLastButtonPressed(id);
  }

  const readText = (event: any) => {
    if (isTtsOn){
      TtsSpeak(event.target.innerText);
    }
  }

  //dataCards in overall view
  var dataCard = (num: number) => {
    return (
      <IonCard class="DataCards"  onClick={() => changeDataDisplay(title[num], `SecurityReferenceCard-${num}`, num)}>
      <IonText class="CardTitle">{title[num]}</IonText>
      </IonCard>
    )
  }

  //datacard in single view mode
  var bigDataCard = (num: number) => {
    switch (num) {
      case 0:
        //Luftgütesensor - VOC-Schwellenwerte
        return (
          <>
          <IonRow class="ReferenceRow">
            <IonCol size="3">
              <b>VOC (ppb)</b>
            </IonCol>
            <IonCol size="3">
              <b>Qualitätsstufe</b>
            </IonCol>
            <IonCol size="3">
              <b>Empfehlung</b>
            </IonCol>
            <IonCol size="3">
            </IonCol>
          </IonRow>
          <IonRow class="ReferenceRow Great">
            <IonCol size="3">
              0-500
            </IonCol>
            <IonCol size="3">
              Keine Bedenken
            </IonCol>
            <IonCol size="3">
              Zielwert
            </IonCol>
            <IonCol size="3">
              Hervorragend
            </IonCol>
          </IonRow>
          <IonRow class="ReferenceRow Good">
            <IonCol size="3">
              500 - 1000
            </IonCol>
            <IonCol size="3">
              Keine relevanten Bedenken
            </IonCol>
            <IonCol size="3">
              Lüften wird empfohlen.
            </IonCol>
            <IonCol size="3">
              Gut
            </IonCol>
          </IonRow>
          <IonRow class="ReferenceRow Average">
            <IonCol size="3">
              1000 – 1600
            </IonCol>
            <IonCol size="3">
              Ein paar Bedenken.
            </IonCol>
            <IonCol size="3">
              Verstärktes Lüften wird empfohlen. Nach Quellen bzw. Ursachen suchen.
            </IonCol>
            <IonCol size="3">
              Mittelmäßig
            </IonCol>
          </IonRow>
          <IonRow class="ReferenceRow Bad">
            <IonCol size="3">
              1600 – 2200
            </IonCol>
            <IonCol size="3">
              Große Bedenken.
            </IonCol>
            <IonCol size="3">
              Verstärktes Lüften notwendig. Nach Quellen suchen.
            </IonCol>
            <IonCol size="3">
              Schlecht
            </IonCol>
          </IonRow>
          <IonRow class="ReferenceRow LastRow Terrible">
            <IonCol size="3">
              {">"} 2200
            </IonCol>
            <IonCol size="3">
              Situation nicht zumutbar.
            </IonCol>
            <IonCol size="3">
              Nur nutzen, wenn unvermeidbar. Nach Quellen suchen.
            </IonCol>
            <IonCol size="3">
              Gesundheitsschädlich
            </IonCol>
          </IonRow>
          </>
        );
        break;
      case 1:
        //Luftgütesensor - Kohlendioxid-Schwellenwerte
        return (
          <>
          <IonRow class="ReferenceRow">
            <IonCol size="2">
              <b>CO2-Wert in ppm</b>
            </IonCol>
            <IonCol size="2">
              <b>CO2-Gehalt in %</b>
            </IonCol>
            <IonCol size="4">
              <b>Qualitätsstufe</b>
            </IonCol>
            <IonCol size="4">
              <b>Empfehlungen</b>
            </IonCol>
          </IonRow>
          <IonRow class="ReferenceRow Great">
            <IonCol size="2">
              Unter 800
            </IonCol>
            <IonCol size="2">
              &lt; 0,08 %
            </IonCol>
            <IonCol size="4">
              Hohe Raumluftqualität
            </IonCol>
            <IonCol size="4">
              Es sind keine Maßnahmen notwendig.
            </IonCol>
          </IonRow>
          <IonRow class="ReferenceRow Average">
            <IonCol size="2">
              800 – 1000
            </IonCol>
            <IonCol size="2">
              0,08 – 0,1 %
            </IonCol>
            <IonCol size="4">
              Mittlere Raumluftqualität
            </IonCol>
            <IonCol size="4">
              Regelmäßiges Lüften wird empfohlen.
            </IonCol>
          </IonRow>
          <IonRow class="ReferenceRow Mediocre">
            <IonCol size="2">
              1000 – 1400
            </IonCol>
            <IonCol size="2">
              0,1 – 0,14 %
            </IonCol>
            <IonCol size="4">
              Mäßige Raumluftqualität
            </IonCol>
            <IonCol size="4">
              Lüften ist notwendig. Lüftungsverhalten überprüfen und verbessern.
            </IonCol>
          </IonRow>
          <IonRow class="ReferenceRow Bad">
            <IonCol size="2">
              1400 – 2000
            </IonCol>
            <IonCol size="2">
              0,14 – 0,20 %
            </IonCol>
            <IonCol size="4">
              Niedrige Raumluftqualität
            </IonCol>
            <IonCol size="4">
              Verstärktes Lüften ist unbedingt notwendig, um Müdigkeit, Schwindel &amp; Konzentrationsschwäche vorzubeugen.
            </IonCol>
          </IonRow>
          <IonRow class="ReferenceRow LastRow Terrible">
            <IonCol size="2">
              Ab 2000
            </IonCol>
            <IonCol size="2">
              &gt; 0,20 %
            </IonCol>
            <IonCol size="4">
              Raumluftqualität unakzeptabel
            </IonCol>
            <IonCol size="4">
              Es muss unverzüglich gelüftet werden. Suche nach Ursachen ist notwendig.
            </IonCol>
          </IonRow>
          </>
          );
        break;
      case 2:
        //Kohlenmonoxid-Melder
        return (
          <>
          <IonRow class="ReferenceRow">
            <IonCol size="3">
              <b>Was Sie hören</b>
            </IonCol>
            <IonCol size="3">
              <b>Was Sie sehen</b>
            </IonCol>
            <IonCol size="3">
              <b>Was es bedeutet</b>
            </IonCol>
            <IonCol size="3">
              <b>Maßnahmen</b>
            </IonCol>
          </IonRow>
          <IonRow class="ReferenceRow Terrible">
            <IonCol size="3">
              4x Piepsen alle 5 Sek.
            </IonCol>
            <IonCol size="3">
              4x Blinken rot alle 30 Sek.
            </IonCol>
            <IonCol size="3">
              Kohlenmonoxid in der Luft ist vorhanden
            </IonCol>
            <IonCol size="3">
              1. Fenster öffnen<br/>
              2. An die frische Luft gehen<br/>
              3. Rettungsdienste kontaktieren
            </IonCol>
          </IonRow>
          <IonRow class="ReferenceRow BlueRow">
            <IonCol size="3">
              3x Piepsen alle 30 Sek.
            </IonCol>
            <IonCol size="3">
              1x Blinken blau alle 30 Sek.
            </IonCol>
            <IonCol size="3">
              Hitzealarm
            </IonCol>
            <IonCol size="3">
              Achtung Feuer
            </IonCol>
          </IonRow>
          <IonRow class="ReferenceRow">
            <IonCol size="3">
              1x Piepsen alle 30 Sek.
            </IonCol>
            <IonCol size="3">
              -
            </IonCol>
            <IonCol size="3">
              Sensorstörung, CO-Erkennung unmöglich
            </IonCol>
            <IonCol size="3">
              Kontaktieren Sie das DeinHaus 4.0 Team!
            </IonCol>
          </IonRow>
          <IonRow class="ReferenceRow TurquoiseRow">
            <IonCol size="3">
              2x Piepsen
            </IonCol>
            <IonCol size="3">
              2x Blinken Türkis alle 30 Sek.
            </IonCol>
            <IonCol size="3">
              Akku leer
            </IonCol>
            <IonCol size="3">
              Kontaktieren Sie das DeinHaus 4.0 Team!
            </IonCol>
          </IonRow>
          <IonRow class="ReferenceRow LastRow Average">
            <IonCol size="3">
              1x Piepsen
            </IonCol>
            <IonCol size="3">
              1x Blinken Gelb alle 30 Sek.
            </IonCol>
            <IonCol size="3">
              Niedriger Batteriestatus
            </IonCol>
            <IonCol size="3">
              Kontaktieren Sie das DeinHaus 4.0 Team!
            </IonCol>
          </IonRow>
          <IonRow class="ReferenceRow">
            <div id="CO-Div"><b>Alarmreaktionszeiten bei Standardeinstellungen:</b></div>
            <ul id="CO-List">
              <li>50 ppm: 60-90 min</li>
              <li>100 ppm: 10-40 min</li>
              <li>300 ppm: {"<"} 1,5 min</li>
            </ul>
          </IonRow>
          </>
        );
        break;

      default:
        return (
          <TtsIonText content="Es ist ein Fehler aufgetreten. Wir entschuldigen uns für die Unannehmlichkeiten." />
        )
        break;
    }
  }


  if (openCard < 0){
    return (
      <IonPage>
          <IonHeader className="header">
          <IonToolbar>
              <IonRow>
                <IonCol size="2" class="ion-text-start">
                <LogButton linkTo="/generally" btnid="ReferenceSecurityData-1" class="BackButton" content="Zurück"/>
                </IonCol>
                <IonCol size="8" class="ion-text-center">
                <IonTitle id="PageTitle">Richtwerte - Wohndaten</IonTitle>
                </IonCol>
                <IonCol size="2" class="ion-text-end">
                </IonCol>
              </IonRow>
              </IonToolbar>
          </IonHeader>
          <IonContent class="DataCardsBox" fullscreen>
          <IonRow class="DataRow">
            <IonCol size="12">
              {dataCard(0)}
              {dataCard(1)}
              {dataCard(2)}
            </IonCol>
          </IonRow>
          </IonContent>
      </IonPage>
    );
  } else {
  return (
    <IonPage>
        <IonHeader className="header">
          <IonToolbar>
            <IonRow>
              <IonCol size="2" class="ion-text-start">
              <IonButton id="SecurityReference-1" class="BackButton" onClick={() => changeDataDisplay("Zurück", "ReferenceSecurityData-1", -1)}>Zurück</IonButton>
              </IonCol>
              <IonCol size="8" class="ion-text-center">
              <IonTitle id="PageTitle">Richtwerte - Wohndaten</IonTitle>
              </IonCol>
              <IonCol size="2" class="ion-text-end">
              </IonCol>
            </IonRow>
          </IonToolbar>
        </IonHeader>
        <IonContent class="DataCardsBox" fullscreen>
          <br/><IonCard class="bigDataCard"  onClick={(event) => readText(event)}>
            <IonCardTitle class="bigCardTitle">{title[openCard]}</IonCardTitle>
            {bigDataCard(openCard)}
          </IonCard>
        </IonContent>
    </IonPage>
  );
}
};



export default ReferenceSecurityData;
