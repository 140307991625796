import { createSlice } from '@reduxjs/toolkit';
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import MQTT from 'async-mqtt';

import {
  FULL_LOG_TRANSMISSION_INTERVAL_IN_MILLIS,
  MQTT_TOPIC_BUTTON,
  MQTT_TOPIC_FULL_BUTTON_LOG,
  URL_LOCAL_MQTT_WSS
} from "../config";

interface ButtonlogItem{
  buttonId: string;
  timestamp: string;
}
interface ButtonlogItems extends Array<ButtonlogItem>{}

interface StateInterface{
  counter: number,
  arr: ButtonlogItems,
  lastFullTransmitTimestampMillis: number,  // Timestamp
}

const initialState: StateInterface = {
  counter: 1,
  arr: [{ buttonId: 'testbtn', timestamp: '6.8.1990, 08:00:00' }],
  lastFullTransmitTimestampMillis: 0,  // Timestamp in milliseconds
};

const persistConfig = {
  key: 'deinhaus',
  storage: storage
};

// @ts-ignore
const transmitFullButtonLog = async (buttonLogs) => {
  const client = await MQTT.connectAsync(URL_LOCAL_MQTT_WSS)

  const payload = {
    timestamp: Date.now(),
    data: buttonLogs,
  }
  try {
    await client.publish(MQTT_TOPIC_FULL_BUTTON_LOG, JSON.stringify(payload));
    await client.end();
  } catch (e) {
    // Do something about it!
    console.log('ERROR while transmitting Full Button Log to Rest2Mqtt! \n' + e);
  }
}

// @ts-ignore
const transmitButtonLog = async (buttonPayload) => {
  const client = await MQTT.connectAsync(URL_LOCAL_MQTT_WSS)

  console.log("Starting");
  try {
    await client.publish(MQTT_TOPIC_BUTTON, JSON.stringify(buttonPayload));
    await client.end();
  } catch (e) {
    console.log('ERROR while transmitting Full Button Log to Rest2Mqtt! \n' + e);
  }
}

const btnlogSlice = createSlice({
  name: 'btnlog',
  initialState,
  reducers: {
    logEntryAdded(state, action) {
      state.arr.push(action.payload);
      state.counter++;

      //Send a message to the MQTT broker to log the button click
      transmitButtonLog(action.payload);

      // Send the Full Button Log (local storage) once a day.
      if(state.lastFullTransmitTimestampMillis < Date.now() - FULL_LOG_TRANSMISSION_INTERVAL_IN_MILLIS) {
        // Last transmit was over 24h ago...
        const buttonLogs = JSON.parse(JSON.stringify(state.arr))
        transmitFullButtonLog(buttonLogs);
        state.lastFullTransmitTimestampMillis = Date.now();  // Set "now transmitted" though successful not confirmed... // TODO Properly implement updating state!

      }
    },
    publishMqttContactRequest(state, action) {
      //mqttService.mqttPublish(mainClient, 'app/contact', JSON.stringify(action.payload));
    },
    logEntryDeleted(state){
      state.arr.shift();
      state.counter--;
    }
  }
});

export const { logEntryAdded, logEntryDeleted, publishMqttContactRequest } = btnlogSlice.actions;

export default persistReducer(persistConfig, btnlogSlice.reducer);
