import { createSlice } from '@reduxjs/toolkit';
import { householdInfo } from './householdInfo';

const sixMonthsFromStart: Date = getSixMonthsFromStart();

function getSixMonthsFromStart(){
  let start = householdInfo.starttime;
  let s = new Date(start);
  let sixMonths = new Date(s.setMonth(s.getMonth()+6));

  return sixMonths;
}

function checkIfArchiveShown(){
  return (checkWhichVideoIsShown()>0)? true : false; 
}



/**Returns which data button is shown
 * 1 means only health data
 * 2 means only security data
 * 3 means both
  */
function checkWhichDataIsShown(){

  let now = Date.now();
  let sixMonthsHavePassed = ((sixMonthsFromStart.getTime()-now) < 0) ? true : false;

  if (sixMonthsHavePassed || householdInfo.group=="3"){
    return 3;
  } else {
    if (householdInfo.group=="2"){
      return 2;
    } else {
      return 1;
    }
  }
}

//Returns the number of the last unlocked video
function checkWhichVideoIsShown(){

  var videonumber = 0;
  var maxVideoNumber = 10; //index is zero based!
  
  if (householdInfo.group==='3'){
    videonumber = getWeeksSinceDate(sixMonthsFromStart.getTime()-604800000); //604800000 is substracted because otherwise the second video would get unlocked after 6 months and a week instead of 6 months
  } else if (householdInfo.group==='1b'){
    videonumber = getWeeksSinceDate(householdInfo.starttime);
  } else {
    videonumber = 0;
  }
  
  if (videonumber <= maxVideoNumber){
    return videonumber;
  } else {
    return maxVideoNumber;
  }
}

//returns how many full weeks since a startdate have passed (startdate in milliseconds), TODO check if this is really a good method
function getWeeksSinceDate(startdate: number){
  //in milliseconds: (current date - start date) / a week (1 week = 604800 seconds)
  let passedWeeks = (Date.now() - startdate) / 604800000;
  //remove the decimals
  passedWeeks = Math.trunc(passedWeeks);
  
  return passedWeeks;
}



interface StateInterface{
  isArchiveShown: boolean,
  dataNumber: number,
  isVideoModalShown: boolean,
  isVideoNotificationShown: boolean,
  videoNumber: number,
  isSurveyAnswered: boolean[]
}

const initialState: StateInterface = {
  isArchiveShown: checkIfArchiveShown(),
  dataNumber: checkWhichDataIsShown(), //1 means only health data, 2 means only security data, 3 means both
  isVideoModalShown: false, //modal appears when a new video gets unlocked for the first time (happens only once); modal is in /components/MenuContainer.tsx
  isVideoNotificationShown: false, //notification appears every time a new video is unlocked (happens once a week until every video is unlocked)
  videoNumber: checkWhichVideoIsShown(), //number of the most recent unlocked video
  isSurveyAnswered: [false, false, false] //shows which questions of the recent survey are answered; TODO isSurveyAnswered might need ReduxPersist
}


const archiveSlice = createSlice({
  name: 'archive',
  initialState,
  reducers: {
    updateArchive: (state) => {

      //Check if a new video gets unlocked
      if (state.videoNumber !== checkWhichVideoIsShown()){
        //reset survey
        state.isSurveyAnswered = [false, false, false];

        //Notify the user about the new video
        if (state.videoNumber==0){
          state.isVideoModalShown = true; //show a modal the first time a new video gets unlocked
        } else {
          state.isVideoNotificationShown = true; //show a notification when the other videos get unlocked
        }
      }
    
      state.isArchiveShown = checkIfArchiveShown();
      state.videoNumber = checkWhichVideoIsShown();
    },
    setVideoModalFalse: (state) => {
      state.isVideoModalShown = false;
    },
    setVideoNotificationFalse: (state) => {
      state.isVideoNotificationShown = false;
    },
    
    setIsSurveyAnsweredTrue: (state, action) => {
      state.isSurveyAnswered[parseInt(action.payload)] = true;
    }
  }
});

export const { updateArchive, setVideoModalFalse, setVideoNotificationFalse, setIsSurveyAnsweredTrue } = archiveSlice.actions;

export default archiveSlice.reducer;