import React from 'react';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonGrid, IonRow, IonCol, IonText, IonFooter, IonImg } from '@ionic/react';
import MenuContainer from '../components/MenuContainer';
import Clock from '../components/Clock';
import './Home.css';
import {LogButton} from '../components/LogButton';
import { ContactNotification } from '../components/ContactNotification';
import { VideoNotification } from '../components/VideoNotification';

const Home: React.FC = () => {
  return (
    <IonPage>
      <ContactNotification/>
      <VideoNotification/>
    <IonHeader class="header">
      <IonToolbar>
        <IonTitle>
          <IonRow>
          <IonCol size="3"class="ion-text-start">
          <IonImg id="logo" src="assets/icon/dh40_logo.png"></IonImg>
            </IonCol>
            <IonCol size="6"class="ion-text-center">
              <IonText id="time"><Clock /></IonText>
            </IonCol>
            <IonCol size="3">
              <IonImg id="logoMinisterium" src="assets/icon/Ministerium_logo.png"></IonImg>
            </IonCol>
          </IonRow>
        </IonTitle>
      </IonToolbar>
    </IonHeader>
    <IonContent class="MenuContainer" fullscreen>
      <MenuContainer />
    </IonContent>
    <IonFooter class="footer">
      <IonGrid>
        <IonRow>
          <IonCol size="3" class="ion-text-start">
            <LogButton linkTo="/help" btnid="Home-4" class="footerButton" image="help" content="Anleitung" />
          </IonCol>
          <IonCol size="6" class="ion-text-center"> &nbsp; </IonCol>
          <IonCol size="3" class="ion-text-end"><LogButton linkTo="/contact" btnid="Home-6" class="footerButton footerButtonSupport" image="person" content=" Kontakt" /></IonCol>
        </IonRow>
      </IonGrid>
    </IonFooter>
  </IonPage>
  );
}

export default Home;
