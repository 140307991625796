// @ts-nocheck
import React, { useEffect, useState } from "react";
import {
  IonCard,
  IonCol,
  IonRow,
  IonSpinner,
  IonText
} from '@ionic/react';
import { ClientOptions, InfluxDB } from "@influxdata/influxdb-client-browser";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDoorClosed, faDoorOpen } from '@fortawesome/free-solid-svg-icons';

import '../pages/Data.css';
import { INFLUX_USERNAME, INFLUX_PASSWORD, INFLUX_BUCKET, URL_INFLUX } from "../config";

const SecurityDataTable: React.FC = ({ dataId, measureds, measuredDetails }) => {

  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  const queries = {
    'dataDoor': `from(bucket: "${INFLUX_BUCKET}") \n  |> range(start: -30d)\n  |> filter(fn: (r) => r._measurement == "mqtt_consumer")\n  |> group(columns: ["nodeLocation"])\n  |> filter(fn: (r) => r.measured_detail == "Door_state")\n  |> toFloat()\n  |> sort(columns: ["_time"], desc: false)\n  |> last()`,
    'dataMoving': '',  // -> SecurityDataHeatmap
    'dataTemp': '',  // -> SecurityDataChart
    'dataPower': '',  // -> SecurityDataChart
    'dataAirQual': '',  // -> SecurityDataChart
    'dataHumid': '',  // -> SecurityDataChart
    'dataCO': '',  // -> SecurityDataChart
    'dataBattery': `from(bucket: "${INFLUX_BUCKET}") \n  |> range(start: -30d) \n  |> filter(fn: (r) => r._measurement == "mqtt_consumer") \n  |> group(columns: ["sensor_id", "measured", "measured_detail"]) \n  |> filter(fn: (r) => r.measured == "level" or r.measured == "isLow") \n  |> sort(columns: ["_time"], desc: true) \n  |> last()`,
  }

  function queryBuilder() {
    if(dataId === 'dataDoor') {
      return queries['dataDoor'];
    }
    else if(dataId === 'dataBattery') {
      return queries['dataBattery'];
    }
    else {
      throw Error('No query for measure defined!')
    }
  }

    /**
     * Based on: Data Visualization Made Easy with ReactJS, Nivo and InfluxDB by InfluxData
     * https://www.influxdata.com/blog/data-visualization-reactjs-nivo-influxdb/
     */
    useEffect(() => {

      let res = [];
      const influxQuery = async () => {
        // Build query
        const query = queryBuilder()
        //console.log('Influx Query: ', query)

        // InfluxDB client options
        const options: ClientOptions = {
          url: URL_INFLUX,
          token: `${INFLUX_USERNAME}:${INFLUX_PASSWORD}`,
        }
        const org = ''  // There is no org in InfluxDB v1
        //create InfluxDB client
        const queryApi = await new InfluxDB(options).getQueryApi(org);
        //make query
        await queryApi.queryRows(query, {
          next(row, tableMeta) {
            const obj = tableMeta.toObject(row);
            //push rows from query into an array object
            res.push(obj);
          },
          complete() {
            // update page state with rows
            setData(res);
          },
          error(error) {
            console.error("ERROR: query failed! ", "Failed query: ", query,
              "\n\n Error: ", error);

            setError(error)
          }
        });
      };

      influxQuery();
    }, []);


  /**
   * Format the door value for cards.
   * @param row
   */
  function formatDoorValue(row) {
    const value = row._value
    if(value == 22) {
      return (<div>
        <div style={{fontSize: 'xxx-large'}}>
          <FontAwesomeIcon icon={ faDoorOpen }></FontAwesomeIcon>
        </div>
        <div>
          Offen { /* `[${value}]` */ }
        </div>
      </div>);
    }
    else if (value == 23) {
      return (<div>
        <div style={{fontSize: 'xxx-large'}}>
          <FontAwesomeIcon icon={ faDoorClosed }></FontAwesomeIcon>
        </div>
        <div>
          Geschlossen { /* `[${value}]` */ }
        </div>
      </div>);
    }
    else {
      return `<pre>${value}</pre>`
    }
  }

  /**
   * Format values, add units and background colors (warning / danger) to values.
   * @param row
   */
  function formatBatteryValue(row) {
    // TODO match all MQTT‐Topic values... (if-elses is probably incomplete)
    if(row.measured === 'Door_state') return <td>{row._value === 0 ? 'Idle' : (row._value === 10 ? 'Replace battery soon' : row._value + ' ???') }</td>  // 0 = Idle, 10=Replace battery soon
    else if(row.measured === 'isLow') return <td className={row._value === 'true' ? 'batteryDanger' : 'batteryGood'}>{ row._value === 'true' ? 'leer ⚠' : 'voll' }</td>  // is Battery Low
    else if(row.measured === 'level') return <td>{ row._value }%</td>  // Battery Level in %
    else if(row.measured === 'Air_temperature') return <td>{row._value}°C</td>
    else if(row.measured === 'Water_Alarm' && row.measured_detail === 'Sensor_status') return <td>{row._value === 0 ? 'Idle' : row._value + ' ???'}</td>
    else if(row.measured === 'Door_state') return <td>{row._value === 22 ? 'Door closed' : (row._value === 23 ? 'Door open' : row._value + ' ???') }</td>  // 22 = Door closed, 23 = Door open
    else if(row.measured === 'Illuminance') return <td>{row._value} Lux</td>
    else if(row.measured === 'Motion_sensor_status') return <td>{row._value === 0 ? 'Idle' : (row._value === 8 ? 'Motion' : row._value + ' ???')}</td>  // 0 = Idle, 8=Motion
    else if(row.measured === 'Power') return <td>{row._value} W</td>
    else if(row.measured_detail === '65537') return <td>{row._value} kWh</td>
    else if(row.measured_detail === 'Carbon_dioxide_CO_level') return <td>{row._value} ppm</td>
    else if(row.measured === 'Humidity') return <td>{row._value} %</td>
    else if(row.measured_detail === 'Dew_point') return <td>{row._value} °C</td>
    else if(row.measured_detail === 'Carbon_monoxide_CO_level') return <td>{row._value} ppm</td>
    else return <td>{row._value}</td>;
  }

  function formatValue(row) {
    switch (dataId) {
      case 'dataDoor': return formatDoorValue(row);
      case 'dataBattery': return formatBatteryValue(row);
      default: return 'N/A';
    }
  }

  function formatTime(time: string) {
    // parse time string
    const parsedTime = new Date( Date.parse(time) )

    // format time as de-DE
    const options = { weekday: 'short', year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
    return parsedTime.toLocaleString('de-DE', options);
  }

  /**
   * Display door/window sensors as large cards instead of table
   */
  function doorWindow() {

    function doorWindowContent() {
      if(error) return (<IonCol size-sm="12"><IonCard class="DataCards"><h2>Fehler beim laden der Daten.</h2> <pre>Fehler: { error.message }</pre></IonCard></IonCol>)
      else if(data && data.length > 0) return doorWindowCards();
      else if(data === null) return ( <IonCol size-sm="12"><IonCard class="DataCards">Hole Daten ... <IonSpinner /> </IonCard></IonCol> )
      else return ( <IonCol size-sm="12"><IonCard class="DataCards"> Keine Daten </IonCard></IonCol> )
    }

    return (
      <IonRow class="DataRow" style={{ textAlign: 'center' }}>
        { doorWindowContent() }
      </IonRow>
    );
  }

  /**
   * @see doorWindow()
   * cards to display the door/window sensors
   */
  function doorWindowCards() {
    return (
      data.map((row, key) => {
        return (
          <IonCard class="DataCards" key={key} onClick={() => changeDataDisplay(dataValues[num].title+" "+dataValues[num].value, `SecurityDataCard-${num}`, num)}>
            <IonText class="CardTitle">
              <h2> {row.nodeName} </h2>
              <h4> Ort: {row.nodeLocation} </h4>
              <h1 style={{fontSize: 'x-large'}}> {formatValue(row)} </h1>
              <pre style={{ fontSize: 'small'}}> {formatTime(row._time)} </pre>
            </IonText>
          </IonCard>
        )
      })
    );
  }

  function dataTable() {
    return (
      <table width="100%" className={'sensorDataTable'}>
        <thead>
        <tr>
          <th>Ort</th>
          <th>Sensor</th>
          <th>Wert</th>
          <th>Zeitpunkt</th>
        </tr>
        </thead>
        <tbody>
        { dataToTableBody() }
        </tbody>
      </table>
    )
  }

  function dataToTableBody() {
    //console.log(data)

    // Display loading / errors
    if(error) return (<tr><td colSpan="6"><h2>Fehler beim laden der Daten.</h2> <pre>Fehler: { error.message }</pre></td></tr>)
    else if(data && data.length > 0) return (
      data.map((row, key) => {
        if(dataId === 'dataMoving' && row._value == 0) {
          return;  // Hide "no motion [0]" in moving table.
        }
        return (
          <tr key={key}>
            <td>{row.nodeLocation}</td>
            <td>{row.nodeName}</td>
            {formatValue(row)}
            <td>{formatTime(row._time)}</td>
          </tr>
        )
      })
    )
    else if(data === null) return ( <tr><td colSpan="6">Hole Daten ... <IonSpinner /> </td></tr> )
    else return ( <tr><td colSpan="6"> Keine Daten </td></tr> )
  }

  // Show data (if available)
  switch (dataId) {
    case 'dataDoor': return doorWindow();
    default: return dataTable();
  }
}

export default SecurityDataTable
