import { IonContent, IonHeader, IonGrid, IonRow, IonCol, IonText, IonIcon, IonButton, IonPage, IonTitle, IonToolbar, IonFooter } from '@ionic/react';
import './Generally.css';
import {List} from '../components/BtnlogList'
import { LogButton } from '../components/LogButton';
import { TtsIonText } from '../components/TtsIonText';

const Generally: React.FC = () => {
  return (
    <IonPage>
      <IonHeader class="header">
      <IonToolbar>
          <IonRow>
            <IonCol size="3" class="ion-text-start">
              <LogButton linkTo="/home" btnid="Generally-1" class="BackButton" content="Zurück"/>
            </IonCol>
            <IonCol size="6" class="ion-text-center">
              <IonTitle id="PageTitle">Allgemeines</IonTitle>
            </IonCol>
            <IonCol size="3" class="ion-text-end">
            </IonCol>
          </IonRow>
        </IonToolbar>
      </IonHeader>
      <IonContent class="MenuContainer" fullscreen>
      <IonGrid>
        <IonRow class="ion-text-center">
          <IonCol size="12">
            <LogButton linkTo="/faq" btnid="Generally-2" class="InfoButton" content="Hilfestellung für Ihr Technikset"/>
          </IonCol>
          <IonCol size="12">
            <LogButton linkTo="/healthreference" btnid="Generally-3" class="InfoButton" content="Richtwerte - Körperdaten"/>
          </IonCol>
          <IonCol size="12">
            <LogButton linkTo="/securityreference" btnid="Generally-4" class="InfoButton" content="Richtwerte - Wohndaten"/>
          </IonCol>
        </IonRow>
      </IonGrid>
      </IonContent>
      <IonFooter class="footer">

      </IonFooter>
    </IonPage>
  );
};

export default Generally;
