import { IonContent, IonHeader, IonRow, IonCol, IonPage, IonTitle, IonToolbar } from '@ionic/react';

import { LogButton } from '../components/LogButton';
import { LogTrainingCard } from '../components/LogTrainingCard';
import { useAppSelector } from '../redux/hooks';
import {HOME_ID_DEMO} from "../config";
import './Archive.css';

const Archive: React.FC = () => {

  const homeId = useAppSelector(state => state.config.homeId);
  const groupId = useAppSelector(state => state.config.groupId);
  const weekOfGroup = useAppSelector(state => state.config.weekOfGroup) ?? 1000;

  /**
   * Show or hide the videos via CSS class.
   * @param videoNumber
   */
  const getVisibilityClass = (videoNumber: number) => {
    if(homeId === HOME_ID_DEMO) {
      // Demo -> Show everything
      console.warn('App is running in DEMO mode [display all videos]')
      return;
    }

    if (videoNumber > weekOfGroup) {
      return "hiddenElement";
    }
  }

  return (
    <IonPage>
      <IonHeader class="header">
        <IonToolbar>
          <IonRow>
            <IonCol size="3" class="ion-text-start">
              <LogButton linkTo="/training" btnid="Archive-1" class="BackButton" content="Zurück" />
            </IonCol>
            <IonCol size="6" class="ion-text-center">
              <IonTitle id="PageTitle">Schulungs-Archiv</IonTitle>
            </IonCol>
            <IonCol size="3" class="ion-text-end">
            </IonCol>
          </IonRow>
        </IonToolbar>
      </IonHeader>

      <IonContent class="TrainingCardsBox" fullscreen>
        <LogTrainingCard linkTo="/training/0" btnid="Archive-2" cardclass="TrainingCards tec" subtitle="Technik" cardtitle="Videobotschaft Prof. Horst Kunhardt" contenttext="" image="assets/img/hk.png" />
        <LogTrainingCard linkTo="/training/1" btnid="Archive-3" cardclass={"TrainingCards tec " + getVisibilityClass(1)} subtitle="Technik" cardtitle="Einführungsschulung" contenttext="" image="assets/img/dh40.png" />
        <LogTrainingCard linkTo="/training/2" btnid="Archive-4" cardclass={"TrainingCards tec " + getVisibilityClass(2)} subtitle="Technik" cardtitle="Gesundheitstechnik" contenttext="" image="assets/img/2.png" />
        <LogTrainingCard linkTo="/training/3" btnid="Archive-5" cardclass={"TrainingCards move " + getVisibilityClass(3)} subtitle="Bewegung" cardtitle="Schlaf" contenttext="" image="assets/img/3.png" />
        <LogTrainingCard linkTo="/training/4" btnid="Archive-6" cardclass={"TrainingCards move " + getVisibilityClass(4)} subtitle="Bewegung" cardtitle="Bewegung" contenttext="" image="assets/img/4.png" />
        <LogTrainingCard linkTo="/training/5" btnid="Archive-7" cardclass={"TrainingCards move " + getVisibilityClass(5)} subtitle="Bewegung" cardtitle="Sturzprävention" contenttext="" image="assets/img/5.png" />
        <LogTrainingCard linkTo="/training/6" btnid="Archive-8" cardclass={"TrainingCards move " + getVisibilityClass(6)} subtitle="Bewegung" cardtitle="Sturzgeschehen" contenttext="" image="assets/img/6.png" />
        <LogTrainingCard linkTo="/training/7" btnid="Archive-9" cardclass={"TrainingCards move " + getVisibilityClass(7)} subtitle="Technik" cardtitle="Sicherheitstechnik" contenttext="" image="assets/img/7.png" />
        <LogTrainingCard linkTo="/training/8" btnid="Archive-10" cardclass={"TrainingCards train " + getVisibilityClass(8)} subtitle="Übungen" cardtitle="Zukunftsvorsorge" contenttext="" image="assets/img/8.png" />
        <LogTrainingCard linkTo="/training/9" btnid="Archive-11" cardclass={"TrainingCards train " + getVisibilityClass(9)} subtitle="Übungen" cardtitle="Soziale Vernetzung" contenttext="" image="assets/img/9.png" />
        <LogTrainingCard linkTo="/training/10" btnid="Archive-12" cardclass={"TrainingCards train " + getVisibilityClass(10)} subtitle="Übungen" cardtitle="Zuversichtlich Altern" contenttext="" image="assets/img/10.png" />
      </IonContent>
    </IonPage>
  );
};

export default Archive;
