import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { persistStore, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from "redux-persist";

import configSlice from './configSlice';
import btnlogReducer from './btnlogSlice';
import ttsReducer from './ttsSlice';
import contactReducer from './contactSlice';
import archiveReducer from './archiveSlice'

const store = configureStore({
  reducer: {
    config: configSlice,
    btnlog: btnlogReducer,
    tts: ttsReducer,
    contact: contactReducer,
    archive: archiveReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);
export default store;

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
