import { useAppDispatch, useAppSelector } from '../redux/hooks';
import {IonCard, IonCol, IonIcon, IonLabel, IonText} from '@ionic/react';
import { close } from 'ionicons/icons';
import { TtsIonText } from './TtsIonText';
import { setContactRequestedFalse } from '../redux/contactSlice';

export function ContactNotification() {
    var isContactRequested = useAppSelector(state => state.contact.isContactRequested);
    
    const dispatch = useAppDispatch();
    const closeNotification = () => {
        dispatch(setContactRequestedFalse());
    }

    if (isContactRequested) {
        return (
            <IonCard id="contactNotification">
                <TtsIonText content={<IonLabel>Wir haben Ihre Nachricht erhalten.<br/>Bald wird jemand bei Ihnen anrufen.</IonLabel>}></TtsIonText>
                <IonIcon size="large" id="notificationClose" icon={close} onClick={closeNotification}/>
            </IonCard>
        );
    } else {
        return(<></>);
    }
}