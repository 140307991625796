/*
 * Provides several URLs and config settings.
 */

/////////////////////////////
// URLs and hosts

// This links open the Withings Health Mate App
// See https://developer.android.com/training/app-links
//     https://stackoverflow.com/a/2958870/1700776
export const WITHINGS_HEALTH_MATE_APP_LINK = 'withings://home';

export const HOST_RASPBERRY_PI = '192.168.1.10';


/////////////////////////////
// Config Provider
// Credentials and config for the InfluxDB API to query data from
export const URL_CONFIG_PROVIDER = 'https://' + HOST_RASPBERRY_PI + ':8080';
export const URL_CONFIG_PROVIDER_HOMEID = URL_CONFIG_PROVIDER + '/homeid.txt';
export const URL_CONFIG_PROVIDER_WITHINGS = URL_CONFIG_PROVIDER + '/config.json';

// Use this home id to unlock and show everything
export const HOME_ID_DEMO = 'DEMODEMO';


/////////////////////////////
// InfluxDB
// Credentials and config for the InfluxDB API to query data from

// Use influxdb-proxy (:8087) with HTTPS and CORS (instead of directly accessing InfluxDB on :8086)
export const URL_INFLUX = `https://${HOST_RASPBERRY_PI}:8087`
export const INFLUX_USERNAME = 'deinhaus40'
export const INFLUX_PASSWORD = 'd3inhaus41'

export const INFLUX_DATABASE = 'sensors'
export const INFLUX_RETENTIONPOLICY = 'autogen'
export const INFLUX_BUCKET = `${INFLUX_DATABASE}/${INFLUX_RETENTIONPOLICY}`


/////////////////////////////
// MQTT
// See the MQTT bridge config of the broker

// Transmit the full button log at max. this often
export const FULL_LOG_TRANSMISSION_INTERVAL_IN_MILLIS = 5 * 60 * 1000;  // 5 minutes

// MQTT Broker (local at home)
export const URL_LOCAL_MQTT_WSS = 'mqtts://' + HOST_RASPBERRY_PI + ':8883';

// Pulish to these topics
export const MQTT_TOPIC_BUTTON = 'app/button_press'
export const MQTT_TOPIC_FULL_BUTTON_LOG = 'app/full_button_log'

// Subscribe to these topics
export const MQTT_SUB_TOPICS = {
  MQTT_SUB_TOPIC_TEST: 'test'
}
