import React, { useState, useEffect, useRef } from 'react';
import './MenuContainer.css';
import {IonGrid, IonRow, IonCol, IonButton, IonIcon, IonCard, IonText, IonCardHeader, IonContent, IonModal, IonHeader} from '@ionic/react';
import { caretDown } from 'ionicons/icons';
import { LogButton } from './LogButton';
import { TtsIonText } from './TtsIonText';
import {useAppDispatch, useAppSelector, useInterval} from '../redux/hooks'
import { updateArchive, setVideoModalFalse } from '../redux/archiveSlice';


interface ContainerProps { }

function Menu(){
  const dispatch = useAppDispatch();

  var isArchiveShown = useAppSelector(state => state.archive.isArchiveShown);
  var isVideoModalShown = useAppSelector(state => state.archive.isVideoModalShown);
  var dataNumber = useAppSelector(state => state.archive.dataNumber);

  //var videoNumber = useAppSelector(state => state.archive.videoNumber);

  const closeNotification = () => {
    dispatch(setVideoModalFalse());
  }

  const getDataButton = () => {
    switch (dataNumber) {
      case 1:
        return(
          <LogButton linkTo="/healthdata" btnid="Home-1" class="menuButton soleDataButton" expand="block" image="heart" imageclass="menuIcon" content="Meine Gesundheitsdaten"/>
        );
        break;
      case 2:
        return(
          <LogButton linkTo="/securitydata" btnid="Home-2" class="menuButton soleDataButton" expand="block" image="home" imageclass="menuIcon" content="Meine Wohndaten" />
        )
        break;
      case 3:
        return(
          <>
          <LogButton linkTo="/healthdata" btnid="Home-1" class="menuButton dataButton" expand="block" image="heart" imageclass="menuIcon" content="Meine Gesundheitsdaten"/>
          <LogButton linkTo="/securitydata" btnid="Home-2" class="menuButton dataButton" expand="block" image="home" imageclass="menuIcon" content="Meine Wohndaten" />
          </>
        );
      default:
        break;
    }
  }

  //Check every 4 hours (=14400000 ms) if the archive may be shown
  useInterval(() => { dispatch(updateArchive()); }, 14400000);

  return (
    <IonGrid class="MenuContent">
      <IonRow class="MenuRow">
        <IonCol class="MenuCol" size="6">
            {/*<LogButton linkTo="/healthdata" btnid="Home-1" class="menuButton dataButton" expand="block" image="heart" imageclass="menuIcon" content="Meine Gesundheitsdaten"/>
            <LogButton linkTo="/securitydata" btnid="Home-2" class="menuButton dataButton" expand="block" image="home" imageclass="menuIcon" content="Meine Wohndaten" />*/}
            {getDataButton()}
            
           <IonContent>
            <IonModal isOpen={isVideoModalShown} cssClass='modal'>
              <TtsIonText class="modalcontent" content={
                <>
                <IonHeader >
                  <p id="modaltitle">Neue Videos</p>
                </IonHeader>
                <p id="modaltext">Es gibt ein neues Video. Sie finden es, wenn Sie auf "Schulungen" (vormals "Einführung") klicken. In den nächsten paar Monaten wird jede Woche ein neues Schulungsvideo für Sie freigeschaltet.</p>
                </>
              } />
              <IonButton onClick={closeNotification}><p id="modalbuttontext">OK</p></IonButton>
            </IonModal>
          </IonContent>

        </IonCol>
        <IonCol class="MenuCol" size="6">
        <LogButton linkTo="/training" btnid="Home-3" class="menuButton" expand="block" image="filmOutline" imageclass="menuIcon" content={isArchiveShown ? "Meine Schulungen" : "Einführung"}/>
        <LogButton linkTo="/generally" btnid="Home-4" class="menuButton" expand="block" image="informationCircle" imageclass="menuIcon" content="Allgemeine Infos"/>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
}

const MenuContainer: React.FC<ContainerProps> = () => {
  return (
    <Menu />
  );
};

export default MenuContainer;
