import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonRow, IonCol, IonIcon, IonCard } from '@ionic/react';
import './Contact.css';
import { LogButton } from '../components/LogButton';
import React from "react";
import { call, mail } from "ionicons/icons";

const Contact: React.FC = () => {

  return (
    <IonPage>
      <IonHeader class="header">
      <IonToolbar>
          <IonRow>
            <IonCol size="3" class="ion-text-start">
              <LogButton linkTo="/home" btnid="Contact-1" class="BackButton" content="Zurück"/>
            </IonCol>
            <IonCol size="6" class="ion-text-center">
              <IonTitle id="PageTitle">Kontakt</IonTitle>
            </IonCol>
            <IonCol size="3" class="ion-text-end">
            </IonCol>
          </IonRow>
        </IonToolbar>
      </IonHeader>
      <IonContent class="DataCardsBox" fullscreen>

        { /* Phone */ }
        <IonRow class="DataRow" style={{ marginTop: "50px", marginBottom: "50px" }}>
          <IonCol size="6" offset="3" style={{textAlign: 'center'}} >

            <p>&nbsp;</p>

            <IonCard id="NoticeCard">
              <IonIcon icon={call} size="large"></IonIcon> <h1 style={{ display: "inline"}}> Telefon: </h1>
              <h1> 0991 3615-5566 </h1>
            </IonCard>

          </IonCol>
        </IonRow>

        { /* E-Mail */ }
        <IonRow class="DataRow" style={{ marginTop: "50px", marginBottom: "50px" }}>
          <IonCol size="6" offset="3" style={{textAlign: 'center'}}>

            <IonCard id="NoticeCard">
              <IonIcon icon={mail} size="large"></IonIcon> &nbsp; <h1 style={{ display: "inline" }}> E-Mail: </h1>
              <h1> deinhaus4.0@th-deg.de </h1>
            </IonCard>

          </IonCol>
        </IonRow>
      </IonContent>
    </IonPage>
  );
};

export default Contact;
