import { useAppDispatch, useAppSelector } from '../redux/hooks';
import {IonCard, IonCol, IonIcon, IonLabel, IonText} from '@ionic/react';
import { close } from 'ionicons/icons';
import { TtsIonText } from './TtsIonText';
import { setVideoNotificationFalse } from '../redux/archiveSlice';

export function VideoNotification() {
    var isVideoNotificationShown = useAppSelector(state => state.archive.isVideoNotificationShown);
    
    const dispatch = useAppDispatch();
    const closeNotification = () => {
        dispatch(setVideoNotificationFalse());
    }

    if (isVideoNotificationShown) {
        return (
            <IonCard id="videoNotification">
                <TtsIonText content='Es gibt ein neues Video.' />
                <IonIcon size="large" id="notificationClose" icon={close} onClick={closeNotification}/>
            </IonCard>
        );
    } else {
        return(<></>);
    }
}