import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { HOME_ID_DEMO, URL_CONFIG_PROVIDER_HOMEID, URL_CONFIG_PROVIDER_WITHINGS } from '../config'

export const GROUP_DEFAULT_ID = 'DEFAULT'

const GROUP_1A = [
  "5AMAU5T5", "2MFC013I", "695JDTTY", "8SNVU83A", "91ASQDEG", "E9PNUQRD", "01V56TVL", "EA0KV6T3", "208MSZIY", "DRAXC026", "FYOUJPR3", "AVQUHATS", "EBNVYNA9", "FCX26SVD", "9Q3VM8A3", "AKG7GAAO", "763722CZ", "JBGNARZ1", "IMWL2G4R", "JRL0UUOI", "KFCD7ECP"
];
export const GROUP_1A_ID = '1a';

const GROUP_1B = [
  "G271KAAU", "FT4YA8Q5", "63XMK5GO", "3JCBHVD2", "DO68U670", "HGLRQU39", "8HP1Q9UL", "AA0L4F44", "8TWO9IRH", "1SF2EITJ", "9JAG8I5Z", "EBB7CY22", "17DWKF12", "CI565EX1", "IYYAAXOH", "I18Q9ZQQ", "HOYTI3FG", "638CIUK5", "79RWQ68M"
]
export const GROUP_1B_ID = '1b';

const GROUP_2 = [
  "264KBQSW", "4II32UB4", "FDUEI2C5", "CMA3CDJE", "1E7GJFX6", "HDH7E3B0", "7EKDN3DV", "7EHZ5SJA", "A4F2S8WA", "BHTCJLEB", "8P4QQ0OZ", "BJZYYOVQ", "G6FWQC7P", "3QSUQTLW", "IO4251KS", "HP3FONBB", "G1EXAZ4L", "EYHHY3ZC"
]
export const GROUP_2_ID = '2';

const GROUP_3 = [
  "38D385FU", "7WN0ASNZ", "GRG7FTVO", "8VFO3BN6", "EK0T2P11", "6LLFM9FF", "155KIX1Q", "AL66YZLE", "5DW2XZK2", "1G9TF4Q3", "HGHIOMGU", "GJ9Y0WSX", "IANC4CUD", "JXOQ8PDZ", "KHR90FEM", "824L356O", "9BQVVT9X", "KDCYBVTP"
]
export const GROUP_3_ID = '3';

export const GROUP_DEMO_ID = 'DEMO';

const initialState = {
  homeId: null,
  statusHomeId: 'idle',
  errorHomeId: null,

  /**
   * The group of the home id (participant)
   */
  groupId: null,
  /**
   * The current week of this group (the shown videos depend on it)
   */
  weekOfGroup: null,

  withingsConfig: {},
  statusWithingsConfig: 'idle',
  errorWithingsConfig: null,
}

/**
 * Redux Thunk.
 * Fetch the homeId from the config provider.
 */
export const fetchHomeId = createAsyncThunk('config/fetchHomeId', async () => {
  const response = await axios.get(URL_CONFIG_PROVIDER_HOMEID);
  const homeId = response.data.trim();  // trim whitespaces
  return homeId
})

/**
 * Find the group of a homeId (participant).
 * @param homeId
 * @return The group id (``GROUP_1A_ID``, ``GROUP_1B_ID``, ``GROUP_2_ID``, ``GROUP_3_ID``) the home id is in - or ``GROUP_DEFAULT_ID``.
 */
// @ts-ignore
const groupOfHomeId = (homeId: string) => {
  if(homeId === HOME_ID_DEMO) {
    console.warn(`App is running in DEMO mode [homeId=${HOME_ID_DEMO}]`)
    return GROUP_DEMO_ID;
  }

  if (GROUP_1A.includes(homeId)) {
    return GROUP_1A_ID;
  } else if (GROUP_1B.includes(homeId)) {
    return GROUP_1B_ID;
  } else if (GROUP_2.includes(homeId)) {
    return GROUP_2_ID;
  } else if (GROUP_3.includes(homeId)) {
    return GROUP_3_ID;
  } else {
    console.error('Home ID "' + homeId + '" was not found in any group!');
    return GROUP_DEFAULT_ID;
  }
}

/**
 * In which week is the participant's group?
 * @param groupId Use ``GROUP_1A_ID``, ``GROUP_1B_ID``, ``GROUP_2_ID``, ``GROUP_3_ID``
 * @return number Integer of the start of the week (1st week would be 1;  2 days after 1st week is also 1).
 */
const weekOfGroup = (groupId: string) => {
  if(groupId === GROUP_DEMO_ID) {
    console.warn(`App is running in DEMO mode [groupId=${GROUP_DEMO_ID}]`)
    return 1000;  // display everything (the higher, the more videos)
  }

  // Start dates per group
  const START_DATE = '2021-12-01';  // YYYY-MM-DD
  const START_DATE_GROUP_3 = '2021-02-01';  // YYYY-MM-DD

  // constants
  const MILLIS_PER_WEEK = 1000 * 3600 * 24 * 7;

  // Match the start date to the group
  let startDate;
  switch (groupId) {
    case GROUP_1A_ID:
      startDate = START_DATE;
      break;
    case GROUP_1B_ID:
      startDate = START_DATE;
      break;
    case GROUP_2_ID:
      startDate = START_DATE;
      break;
    case GROUP_3_ID:
      startDate = START_DATE_GROUP_3;
      break;
    default:
      console.error('Group "' + groupId + '" not found.');
      return 0;
  }

  // Find the week number of the participant
  const start = Date.parse(startDate)
  const today = new Date().getTime();
  const week = (today - start) / (MILLIS_PER_WEEK);
  return Math.floor(week);  // Start of the week -> round down
}

/**
 * Redux Thunk.
 * Fetch the withings config and tokens from the config provider.
 */
export const fetchWithingsConfig = createAsyncThunk('config/fetchWithingsConfig', async () => {
  const response = await axios.get(URL_CONFIG_PROVIDER_WITHINGS);
  const withingsConfig = JSON.parse(response.data);
  return withingsConfig;
})

const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      // home id
      .addCase(fetchHomeId.pending, (state, action) => {
        state.statusHomeId = 'loading'
      })
      .addCase(fetchHomeId.fulfilled, (state, action) => {
        state.statusHomeId = 'succeeded'
        state.homeId = action.payload  // set home id
        // @ts-ignore
        state.groupId = groupOfHomeId(state.homeId)  // set derived group id
        // @ts-ignore
        state.weekOfGroup = weekOfGroup(state.groupId)
        console.log('homeId:', state.homeId, ';  groupId:', state.groupId, ';  weekOfGroup:', state.weekOfGroup);
      })
      .addCase(fetchHomeId.rejected, (state, action) => {
        state.statusHomeId = 'failed'
        // @ts-ignore
        state.errorHomeId = action.error.message
      })

      // withings config
      .addCase(fetchWithingsConfig.pending, (state, action) => {
        state.statusWithingsConfig = 'loading'
      })
      .addCase(fetchWithingsConfig.fulfilled, (state, action) => {
        state.statusWithingsConfig = 'succeeded'
        state.withingsConfig = { ...state.withingsConfig, ...action.payload }
      })
      .addCase(fetchWithingsConfig.rejected, (state, action) => {
        state.statusWithingsConfig = 'failed'
        // @ts-ignore
        state.errorWithingsConfig = action.error.message
      })
  },
})

export default configSlice.reducer

// @ts-ignore
export const getHomeId = (state) => state.config.homeId

// @ts-ignore
export const getGroupId = (state) => state.config.groupId

// @ts-ignore
export const getWeekOfGroup = (state) => state.config.weekOfGroup

// @ts-ignore
export const getWithingsConfig = (state) => state.config.withingsConfig
