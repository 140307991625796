import { createSlice } from '@reduxjs/toolkit';


const initialState = {
  isContactRequested: false
}


const contactSlice = createSlice({
  name: 'contact',
  initialState,
  reducers: {
    setContactRequestedTrue: (state) => {
      state.isContactRequested = true;
    },
    setContactRequestedFalse: (state) => {
      state.isContactRequested = false;
    },
  }
});

export const { setContactRequestedTrue, setContactRequestedFalse } = contactSlice.actions;

export default contactSlice.reducer;