import { useEffect } from 'react'
import { Redirect, Route } from 'react-router-dom';
import { IonApp, IonRouterOutlet } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { useSelector, useDispatch } from 'react-redux'

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

/* Components of routes */
import Home from './pages/Home';
import Training from './pages/Training';
import Contact from './pages/Contact';
import Generally from './pages/Generally';
import Archive from './pages/Archive';
import Survey from './pages/Survey';
import HealthData from './pages/HealthData';
import SecurityData from './pages/SecurityData';
import ReferenceHealthData from './pages/ReferenceHealthData';
import ReferenceSecurity from './pages/ReferenceSecurityData';
import FAQ from './pages/FAQ';
import Help from './pages/Help';
import Development from "./pages/Development";

/* Redux state */
import { fetchHomeId, fetchWithingsConfig } from './redux/configSlice'

const App = () => {
  const dispatch = useDispatch()

  // fetch Home ID
  // @ts-ignore
  const statusHomeId = useSelector((state) => state.config.statusHomeId)
  useEffect(() => {
    if (statusHomeId === 'idle') {
      dispatch(fetchHomeId())
    }
  }, [statusHomeId, dispatch])

  // fetch Withings config
  // @ts-ignore
  const statusWithingsConfig = useSelector((state) => state.config.statusWithingsConfig)
  useEffect(() => {
    if (statusWithingsConfig === 'idle') {
      // TODO Withings config is dead...
      //dispatch(fetchWithingsConfig())
    }
  }, [statusWithingsConfig, dispatch])

  return (
    <IonApp>
      <IonReactRouter>
        <IonRouterOutlet>
          <Route exact path="/home">
            <Home />
          </Route>
          <Route exact path="/">
            <Redirect to="/home" />
          </Route>
          <Route exact path="/training">
            <Training />
          </Route>
          <Route exact path="/training/:videoid">
            <Training />
          </Route>
          <Route exact path="/contact">
            <Contact />
          </Route>
          <Route exact path="/generally">
            <Generally />
          </Route>
          <Route exact path="/archive">
            <Archive />
          </Route>
          <Route exact path="/survey">
            <Survey />
          </Route>
          <Route exact path="/healthdata">
            <HealthData />
          </Route>
          <Route exact path="/securitydata">
            <SecurityData />
          </Route>
          <Route exact path="/healthreference">
            <ReferenceHealthData />
          </Route>
          <Route exact path="/securityreference">
            <ReferenceSecurity />
          </Route>
          <Route exact path="/faq">
            <FAQ />
          </Route>
          <Route exact path="/help">
            <Help />
          </Route>
          <Route exact path="/development">
            <Development />
          </Route>
        </IonRouterOutlet>
      </IonReactRouter>
    </IonApp>
  )
};

export default App;
